<script setup>
import { collectionV2 } from '@/composables/resource';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Content from '@/views/_partials/Content.vue';
import EmptyList from '@/components/Renderer/EmptyList.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import IconSearch from '@/components/Icons/Search.vue';
import Pill from '@/components/Pills/Pill.vue';
import PillGroup from '@/components/Pills/PillGroup.vue';
import ProfileLink from '@/components/Link/ProfileLink.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';
import UserCard from '@/components/User/UserCard.vue';

const route = useRoute();
const router = useRouter();
const header = ref();

const accepted = collectionV2({
    url: '/api/user-connections',
    params: {
        filters: {
            accepted: true,
        },
    },
});

const pending = collectionV2({
    url: '/api/user-connections',
    params: {
        filters: {
            accepted: false,
        },
    },
});

const suggestions = collectionV2({
    url: '/api/user-connect-suggestions',
});

const collection = computed(() => {
    if (route.query.tab === 'accepted') {
        return accepted;
    } else if (route.query.tab === 'pending') {
        return pending;
    } else if (route.query.tab === 'suggestions') {
        return suggestions;
    }

    return accepted;
});

const tabTo = (tab) => {
    router.replace({
        query: {
            ...route.query,
            tab,
        },
    }).then(() => {
        collection.value.fetchOnce();
    });
};

onMounted(() => {
    collection.value.fetch();
});
</script>

<template>
    <Content>
        <Header with-back-button ref="header" :search-collection="collection" search-placeholder="Search for a user">
            <HeaderTitle>
                Connections
            </HeaderTitle>

            <div class="grow"></div>
            <button @click="header.openSearch" type="button" aria-label="Open search">
                <IconSearch class="size-7" />
            </button>
        </Header>

        <div class="px-4 mt-6">
            <PillGroup>
                <Pill type="button" as="button" :data-active="!route.query.tab" @click="() => tabTo()">
                    Accepted
                </Pill>
                <Pill type="button" as="button" :data-active="route.query.tab === 'pending'" @click="() => tabTo('pending')">
                    Pending
                </Pill>
                <Pill type="button" as="button" :data-active="route.query.tab === 'suggestions'" @click="() => tabTo('suggestions')">
                    Suggestions
                </Pill>
            </PillGroup>
            <div class="mt-3">
                <Skeleton :collection="collection" minimal></Skeleton>
                <EmptyList :collection="collection" />
                <ul class="flex flex-col gap-2" v-if="collection.data">
                    <li v-for="data in collection.data" :key="data.id">
                        <ProfileLink :profile="route.query.tab !== 'suggestions' ? data.profile : data.suggested_user.profile">
                            <UserCard :user="route.query.tab !== 'suggestions' ? data : data.suggested_user" />
                        </ProfileLink>
                    </li>
                </ul>
                <InfiniteScrollObserver :collection="collection" />
            </div>
        </div>
    </Content>
</template>
