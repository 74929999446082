import { trackViewEvent, trackClickEvent, trackImpressionEvent } from '@/utils/te';

const trackers = [];

export const eventTracker = {
    mounted(el, binding) {
        const value = binding.value;
        const event = binding.arg;
        const trackingData = typeof value === 'function' ? value() : value;

        if (!trackingData || !trackingData.item_id || !trackingData.item_type) {
            return;
        }

        if (event === 'view') {
            trackers.push({
                el,
                event: trackViewEvent(el, trackingData),
            });
        } else if (event === 'click') {
            trackers.push({
                el,
                event: trackClickEvent(el, trackingData),
            });
        } else if (event === 'impression') {
            trackers.push({
                el,
                event: trackImpressionEvent(el, trackingData),
            });
        } else {
            throw new Error(`Invalid event type: ${event}`);
        }
    },
    unmounted(el) {
        trackers.forEach((tracker) => {
            if (tracker.el === el) {
                tracker.event.destroy();
            }
        });
    }
}
