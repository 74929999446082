<script setup>
import { computed, onMounted, ref, watch } from 'vue';

import { ProgressIndicator, ProgressRoot } from 'radix-vue';

const props = defineProps({
    value: {
        type: Number,
        default: 0,
    },
    max: {
        type: Number,
        default: 100,
    },
    title: {
        type: String,
        default: 100,
    },
    color: {
        type: String,
        default: 'primary-light',
    },
});

const modelValue = ref(0);

const setModelValue = (value) => {
    const timer = setTimeout(() => (modelValue.value = props.value), 250);
    return () => clearTimeout(timer);
};

watch(() => props.value, () => {
    return setModelValue();
});

onMounted(() => {
    return setModelValue();
});
</script>
<template>
    <div>
        <ProgressRoot
            v-model="modelValue"
            class="relative overflow-hidden rounded-[inherit] w-full h-full"
            style="transform: translateZ(0)"
            :max="max"
            :get-value-label="() => `${title} ${modelValue}%`"
        >
            <ProgressIndicator
                class="
                    text-center rounded-[inherit]
                    w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]
                "
                :class="{
                    'rounded-r-none': modelValue < max,
                    'bg-primary': color == 'primary',
                    'bg-primary/60': color == 'primary-light',
                    'bg-warning': color == 'warning',
                    'bg-warning/60': color == 'warning-light',
                }"
                :style="`transform: translateX(-${100 - modelValue}%)`"
            />
        </ProgressRoot>
        <slot :label="`${100 - modelValue}%`" />
    </div>
</template>
