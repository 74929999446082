import axios from '@/axios';

const postTrackingEvent = (trackingData) => {
    axios.post('/te', trackingData)
        .catch(async (error) => {
            if (error.response && error.response.status === 419) {
                await axios.get('/sanctum/csrf-cookie');
                postTrackingEvent(trackingData);
            } else {
                console.error('error making request', error);
            }
        })
}

export const trackViewEvent = (el, trackingData) => {
    const observer = typeof IntersectionObserver === 'undefined' ? null : new IntersectionObserver(
        (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
                postTrackingEvent({
                    ...trackingData,
                    type: 2,
                });
                observer.disconnect();
            }
        },
        {
            root: null, // observing for viewport
            rootMargin: '0px',
            threshold: props.threshold,
        }
    );

    observer?.observe(el);

    return {
        destroy: () => {
            observer?.disconnect();
        }
    };
}

export const trackClickEvent = (el, trackingData) => {
    //
}

export const trackImpressionEvent = (el, trackingData) => {
    //
}
