<script setup>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { collection } from '@/composables/resource';

import PostList from '@/components/Post/PostList.vue';
import Content from '@/views/_partials/Content.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';

const props = defineProps({
    pageData: {
        type: Object,
        required: false,
    }
});

const route = useRoute();

const questions = collection({
    url: '/api/search',
    filter: {
        query: route.query.query,
        type: 'question',
    }
});

onMounted(() => {
    if (!questions.fetched) {
        questions.fetch();
    }
});
</script>

<template>
    <div class="px-4">
        <PostList :collection="questions" enable-tracking tracking-source="search" />
    </div>
</template>
