<script setup>
import { h, computed } from "vue";
import { RouterLink } from "vue-router";

const props = defineProps({
    content: {
        type: String,
        required: false,
    },
    bg: {
        type: String,
        required: false,
        default: 'white',
    },
    mentions: {
        type: Array,
        required: false,
        default: [],
    }
});

const formatMentions = (content) => {
    return content.replace(/@([\w_-]+)/g, (mention) => {
        const username = mention.split('@', 2)[1];

        const anonymousUser = props.mentions.find(m => m.is_anonymous);

        if (anonymousUser && username === 'anonymoususer') {
            return `<span data-type="anonymoususer">${mention}</span>`;
        }

        if (props.mentions.find(m => m.username === username)) {
            return `<a href="/profiles/${username}" data-type="mention">${mention}</a>`;
        }

        return mention;
    });
}

const formatLink = (content) => {
    return content.replace(/https?:\/\/[^\s]+/g, (url) => {
        if (url.startsWith(import.meta.env.VITE_APP_URL)) {
            return `<a href="${url}" data-type="link">${url}</a>`;
        }

        return `<a href="${url}" data-type="link" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
}

const formatBold = (content) => {
    return content.replace(/\*(.*?)\*/g, '<b>$1</b>');
}

const formattedContent = computed(() => {
    if (!props.content) {
        return '';
    }

    let content = props.content;

    content = formatLink(content);
    content = formatMentions(content);
    content = formatBold(content);

    return content;
});
</script>

<style lang="postcss">
.content {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
}
.content[data-bg="white"] {
    a[data-type="mention"] {
        @apply bg-primary/20 text-primary;
    }

    span[data-type="anonymoususer"] {
        @apply bg-info/20 text-info;
    }

    a[data-type="link"] {
        @apply text-primary;
    }
}
.content[data-bg="primary"] {
    a[data-type="mention"] {
        @apply bg-primary-foreground/20 text-primary-foreground;
    }

    a[data-type="link"] {
        @apply text-primary-foreground underline;
    }
}
</style>

<template>
    <div :data-bg="bg" class="content" v-html="formattedContent"></div>
</template>
