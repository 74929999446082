<script setup>
import { computed, onMounted, ref } from 'vue';

import { useMyFeed } from '@/composables/feed';
import { useNotificationPermission, promptOpened } from '@/composables/permissions/notification';
import { usePwaInstall } from '@/plugins/pwa-install';
import { useStore } from '@/store';
import { registerFileUploader } from '@/views/files/_helpers';

import Avatar from '@/components/Avatar.vue';
import Content from '@/views/_partials/Content.vue';
import EmptyList from '@/components/Renderer/EmptyList.vue';
import IconPencilPlus from '@/components/Icons/PencilPlus.vue';
import InfiniteScrollObserver from '@/components/Renderer/InfiniteScrollObserver.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import ProfileDrawer from '@/components/ProfileDrawer.vue';
import PostListItem from '@/components/Post/PostListItem.vue';
import ReputationScoreCampaign from '@/components/PopupCampaigns/ReputationScoreCampaign.vue';
import Search from '@/components/Search.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';

registerFileUploader();
const showProfileDrawer = ref(false);
const feed = useMyFeed();
const store = useStore();
const pwa = usePwaInstall();

const campaign = ref(null);

const isInstallAvailable = ref(pwa.isInstallAvailable);

const openPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=ng.corperland.twa', '_blank');
};

const popupCampaign = () => {
    if (!store.state.user?.profile || promptOpened.value || store.state.feedPromptOpened) {
        return;
    }

    // campaigns that has not been viewed, order by oldest
    let campaigns = Object.keys(store.state.user.popup_campaigns ?? {})
        .filter(key => store.state.user.popup_campaigns[key].viewed_at == null)
        .sort((a, b) => {
            return store.state.user.popup_campaigns[a].created_at - store.state.user.popup_campaigns[b].created_at;
        });

    if (campaigns.length === 0) {
        // campaigns that has been viewed, order by newest
        campaigns = Object.keys(store.state.user.popup_campaigns ?? {})
            .sort((a, b) => {
                return store.state.user.popup_campaigns[b].created_at - store.state.user.popup_campaigns[a].created_at;
            });
    }

    const key = campaigns[0];

    if (!key) {
        return;
    }

    const hidden = store.state.user.popup_campaigns[key].hidden == true;

    if (hidden) {
        return;
    }

    store.commit('feedPromptOpened', true);

    setTimeout(() => {
        campaign.value = key;
    }, 2000);
}

onMounted(() => {
    setTimeout(() => {
        isInstallAvailable.value = pwa.isInstallAvailable;
    }, 1200);

    useNotificationPermission().promptOnce();

    popupCampaign();
});
</script>

<template>
    <Content>
        <Header>
            <button class="flex" type="button" @click="showProfileDrawer = true" aria-label="show profile drawer">
                <Avatar class="md:hidden" :src="$store.state.user.profile.avatar?.url" :fallback-from="$store.state.user.name" />
                <ProfileDrawer :open="showProfileDrawer" @update:open="showProfileDrawer = false" />
            </button>
            <HeaderTitle>
                Feed
            </HeaderTitle>
            <div class="grow"></div>
            <template v-if="$store.state.deviceType === 'mobile' && pwa && isInstallAvailable && !pwa.isUnderStandaloneMode">
                <button @click="pwa.isAppleMobilePlatform ? pwa.showDialog() : openPlayStore()" class="block font-semibold bg-primary text-primary-foreground rounded-full px-2 py-1">
                    Open app
                </button>
            </template>
            <Search class="ml-auto" :placeholder="`Search for Posts on Corperland`" type="post" />
        </Header>

        <div class="py-4 md:px-4 bg-gray h-full">
            <div>
                <Skeleton :collection="feed.posts" />
                <EmptyList :collection="feed.posts" />
                <observable-elements class="flex flex-col gap-2" v-if="feed.posts.data">
                    <template v-for="post in feed.posts.data" :key="post.id">
                        <PostListItem as="div" v-if="!post.__client_meta?.injected" :post="post" enable-tracking tracking-source="feed" />
                        <component v-else :is="post.component" v-bind="post.props" />
                    </template>
                </observable-elements>
                <InfiniteScrollObserver :collection="feed.posts" />
            </div>
        </div>

        <div class="fixed bottom-20 right-6 z-50" v-if="$store.state.deviceType === 'mobile'">
            <button
                @click.prevent="$router.push({ name: 'posts.create' })"
                type="button"
                aria-label="Create Post"
                class="bg-primary text-primary-foreground rounded-full p-2"
            >
                <IconPencilPlus class="size-7" />
            </button>
        </div>

        <ReputationScoreCampaign v-if="campaign === 'reputation_score'" />
        <router-view />
    </Content>
</template>
