<script setup>
import { inject } from 'vue';

import { getFriendlyDuration } from '@/utils';

import ControlSlider from '@/components/File/ControlSlider.vue';
import IconPlay from '@/components/Icons/Play.vue';
import IconPause from '@/components/Icons/Pause.vue';
import IconVolumeHigh from '@/components/Icons/VolumeHigh.vue';
import IconVolumeOff from '@/components/Icons/VolumeOff.vue';

const player = inject('mediaPlayer');
</script>

<template>
    <div v-if="player" class="w-full">
        <div class="w-full">
            <ControlSlider />
        </div>
        <div class="flex items-center mt-3">
            <button :disabled="player.readyState !== 4 && player.readyState !== 1" class="block disabled:opacity-70" @click.stop.prevent="player.togglePlay()" aria-label="Play media">
                <component :is="player.paused ? IconPlay : IconPause" class="size-6" aria-hidden="true" />
            </button>
            <div class="text-sm ml-2">
                {{ getFriendlyDuration(player.currentTime) }} / {{ getFriendlyDuration(player.duration) }}
            </div>
            <div class="grow"></div>
            <button :disabled="player.readyState !== 4 && player.readyState !== 1" class="block" @click.stop.prevent="player.toggleMute()" aria-label="Mute media">
                <component :is="player.muted ? IconVolumeOff : IconVolumeHigh" class="size-6" aria-hidden="true" />
            </button>
        </div>
    </div>
</template>
