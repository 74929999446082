import axios from '@/axios';

import { deepCopy } from '@/utils';
import echo from '@/plugins/echo';
import { useToast } from "@/plugins/toast";
import { computed, reactive, ref } from "vue";

export const useAjaxForm = (fields) => {
    const id = Math.random().toString(36).substring(2, 9);
    fields = fields ? deepCopy(fields) : {};
    const formattedFields = {};
    Object.keys(fields).forEach(key => {
        formattedFields[key] = typeof fields[key] === 'undefined' ? null : fields[key];
    });

    const validations = ref([]);
    const beforeSubmitCallbacks = [];
    const onErrorCallbacks = [];
    const onSuccessCallbacks = [];
    const onFinallyCallbacks = [];
    let lastSubmit = null;
    let toast = null;
    let retrievesCsrf = false;

    try {
        toast = useToast();
    } catch (error) {
        console.warn(`useForm should be called in setup for toast to work`);
    }

    return reactive({
        ...formattedFields,
        _original: deepCopy(fields),
        errors: {},
        processing: false,
        isValid: computed(() => {
            if (validations.value.length === 0) {
                return true;
            }

            return validations.value.every((callback) => {
                return !!callback();
            });
        }),
        keys: Object.keys(fields),
        status: null,
        retrievesCsrf: (val = true) => retrievesCsrf = val,
        getId() {
            return id;
        },
        data() {
            const data = {};

            this.keys.forEach(key => data[key] = this[key]);

            return data;
        },
        isDirty() {
            return Object.keys(this.dirtyData()).length > 0;
        },
        isClean() {
            return !this.isDirty();
        },
        dirtyData() {
            const data = this.data();
            const dirty = {};

            Object.keys(data)
                .filter((key) => {
                    return JSON.stringify(this._original[key]) !== JSON.stringify(data[key]);
                })
                .forEach((key) => dirty[key] = data[key]);

            return dirty;
        },
        clear(fields = null) {
            this.keys.forEach(key => {
                if (!fields || fields.includes(key)) {
                    this[key] = null;
                }
            });
        },
        reset(fields = null) {
            this.keys.forEach(key => {
                if ((!fields || fields.includes(key))) {
                    this[key] = this._original[key];
                }
            });
        },
        beforeSubmit(callback) {
            beforeSubmitCallbacks.unshift(callback);
        },
        onSuccess(callback) {
            onSuccessCallbacks.unshift(callback);
        },
        onError(callback) {
            onErrorCallbacks.unshift(callback);
        },
        onFinally(callback) {
            onFinallyCallbacks.unshift(callback);
        },
        validate(callback) {
            validations.value.unshift(callback);
        },
        async delete(url, options, retrying = false) {
            return this._submit(url, {
                method: 'DELETE',
            }, options, retrying);
        },
        async post(url, options, retrying = false) {
            const extraData = options?.data ?? {};
            return this._submit(url, {
                method: 'POST',
                data: {...this.data(), ...extraData},
            }, options, retrying);
        },
        async patch(url, options, retrying = false) {
            const data = this.data();

            return this._submit(url, {
                method: 'POST',
                data: {...data, ...{_method: 'PATCH'}},
            }, options, retrying);
        },
        async get(url, params) {
            // retu
        },
        async retry() {
            if (!lastSubmit) {
                return false;
            }

            return this._submit(lastSubmit.url, lastSubmit.config, {
                onSuccess: lastSubmit.options.onSuccess,
                transformRequest: lastSubmit.options.transformRequest,
            }, true);
        },
        async _submit(url, config, { onSuccess, onFinally, onError, transformRequest } = {}, retrying = false) {
            config = { ...config, transformRequest };
            config.headers = config.headers ?? {};
            config.headers['X-Socket-ID'] = echo.socketId();

            beforeSubmitCallbacks.forEach((callback) => callback(config, retrying))

            this.processing = true;
            this.status = null;
            this.errors = {};

            if (retrievesCsrf) {
                await axios.get('/sanctum/csrf-cookie');
            }

            return axios(url, config)
                .then(response => {
                    this.status = 200;

                    onSuccessCallbacks.forEach((callback) => callback(response));

                    if (onSuccess) {
                        onSuccess(response);
                    }

                    return response;
                })
                .catch(error => {
                    if (error.response) {
                        this.status = error.response.status;

                        if (error.response.status === 429) {
                            toast?.publish({
                                title: 'Too many requests. Please try again later.',
                                color: 'danger',
                            });
                        } else if (error.response.status === 422) {
                            for (let obj in error.response.data.errors) {
                                this.errors[obj] = error.response.data.errors[obj][0];
                            }
                        } else if (error.response.status === 400) {
                            const twoFaRequired = error.response.headers['x-two-fa-required'];

                            if (!twoFaRequired) {
                                toast?.publish({
                                    title: error.response.data.message || 'Invalid request.',
                                    color: 'danger',
                                });
                            }
                        } else if (error.response.status === 401) {
                            toast?.publish({
                                title: 'Login required.',
                                color: 'danger',
                            });
                        } else if (error.response.status === 403) {
                            toast?.publish({
                                title: 'Unauthorized request.',
                                color: 'danger',
                            });
                        } else if (error.response.status === 500) {
                            toast?.publish({
                                title: 'An error occured. Please try again later.',
                                color: 'danger',
                            });
                        } else if (error.response.status === 449) {
                            // do nothing. action required
                        } else {
                            toast?.publish({
                                title: 'An error occured. Please try again later.',
                                color: 'danger',
                            });

                            throw new Error(error.response.data.message);
                        }
                    } else if (error.request) {
                        toast?.publish({
                            title: 'Request failed. Please check your internet connection and try again.',
                            color: 'danger',
                        })
                    }

                    onErrorCallbacks.forEach((callback) => callback(error));

                    if (onError) {
                        onError(error);
                    }

                    return error;
                })
                .finally(() => {
                    this.processing = false;
                    lastSubmit = {
                        url,
                        config,
                        options: {
                            onSuccess,
                            transformRequest,
                        }
                    }

                    onFinallyCallbacks.forEach((callback) => callback());

                    if (onFinally) {
                        onFinally();
                    }
                });
        }
    });
}
