<script setup>
import { inject } from 'vue';

import IconPlay from '@/components/Icons/Play.vue';
import IconPause from '@/components/Icons/Pause.vue';

const player = inject('mediaPlayer');
</script>

<template>
    <button @click.stop.prevent="player.togglePlay()" aria-label="Mute media">
        <slot :paused="player.paused">
            <component :is="player.paused ? IconPlay : IconPause" class="size-6" aria-hidden="true" />
        </slot>
    </button>
</template>
