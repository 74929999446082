<script setup>
import { useToast } from '@/plugins/toast';

import { registerFileUploader } from '@/views/files/_helpers/index';

import Content from '@/views/_partials/Content.vue';
import Form from '@/components/Channel/Form.vue';
import IconTrashCanOutline from '@/components/Icons/TrashCanOutline.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import Uploader from '@/views/files/Uploader.vue';

registerFileUploader({
    queryMode: true,
});

const props = defineProps({
    channel: {
        required: true,
        type: Object,
    },
});

const toast = useToast();

const updated = (response) => {
    toast.success('Channel updated successfully');
    props.channel.fill(response);
}
</script>

<template>
    <Content>
        <Header with-back-button>
            <HeaderTitle>Edit Channel</HeaderTitle>

            <!-- <button type="button" aria-label="delete channel" class="ml-auto block text-danger">
                <IconTrashCanOutline class="size-7" />
            </button> -->
        </Header>

        <div v-if="channel.filled()" class="mt-4 px-4">
            <Form :channel="channel.data" @updated="updated" />
        </div>
        <Uploader v-if="$route.query.modal === 'file-uploader'" />
    </Content>
</template>
