<script setup>
import IconStar from '@/components/Icons/Star.vue';

const props = defineProps({
    score: {
        type: Number,
        required: true,
    },
    size: {
        type: String,
        default: '6',
    },
});

const sizes = {
    '1': 'size-1',
    '2': 'size-2',
    '3': 'size-3',
    '4': 'size-4',
    '5': 'size-5',
    '6': 'size-6',
    '7': 'size-7',
    '8': 'size-8',
    '9': 'size-9',
}
</script>

<template>
    <div v-if="score >= 750">
        <IconStar class="text-[#46c046]" :class="sizes[size]" v-if="score >= 3000" />
        <IconStar class="text-[#efd751]" :class="sizes[size]" v-else-if="score >= 2000" />
        <IconStar class="text-[#db9c49]" :class="sizes[size]" v-else-if="score >= 750" />
    </div>
</template>
