import { provide } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export function registerFileGallery() {
    const router = useRouter();
    const route = useRoute();

    let data = null;

    const open = (files) => {
        data = {
            files: typeof files == 'arr' ? [files] : files,
        }
    }
}

export function registerFileUploader(options = {}) {
    const router = useRouter();
    const route = useRoute();

    let data = null;

    const startUpload = (pickerData) => {
        data = pickerData;
        if (options.queryMode) {
            router.push({
                query: {
                    ...route.query,
                    modal: 'file-uploader',
                },
            });
        } else {
            router.push(route.path + (route.path.endsWith('/') ? '' : '/') + 'file-uploader');
        }
    }

    const getData = () => {
        return data;
    }

    const destroy = () => {
        data = null;
    }

    provide('fileUploaderGetData', getData);
    provide('fileUploaderStartUpload', startUpload);
    provide('fileUploaderDestroy', destroy);
}
