<script setup>
import { DialogRoot, DialogPortal } from 'radix-vue';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const emit = defineEmits(['update:open']);

const props = defineProps({
    open: {
        type: Boolean,
        default: false
    },
    id: {
        type: String,
        default: null
    }
});
const router = useRouter();
const route = useRoute();

const registerRouteModal = () => {
    if (!props.id) return;

    if (route.query.modal == props.id) {
        emit('update:open', true);
    } else if (props.open == true) {
        router.push({
            path: route.path,
            params: route.params,
            query: {
                modal: props.id,
                ...route.query
            }
        })
    }

    watch(() => route.query.modal, (modal) => {
        if (modal == props.id) {
            emit('update:open', true);
        } else {
            emit('update:open', false);
        }
    });

    watch(() => props.open, (open) => {
        if (open) {
            router.push({
                path: route.path,
                params: route.params,
                query: {
                    modal: props.id,
                    ...route.query
                }
            })
        } else if (route.query.modal == props.id) {
            router.back();
        }
    });
}

onMounted(() => {
    registerRouteModal();
});
</script>

<template>
    <DialogRoot :open="open" @update:open="$emit('update:open', $event)">
        <DialogPortal>
            <slot />
        </DialogPortal>
    </DialogRoot>
</template>
