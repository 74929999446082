import { computed, onMounted, reactive, ref } from 'vue';
import { collectionV2, useResource } from '@/composables/resource';
import { useStore } from '@/store';
import { mem_store } from '@/utils/cache';
import { usePostListener } from '@/composables/listeners';
import axios from '@/axios';
import echo from '@/plugins/echo';
import { useRoute, useRouter } from 'vue-router';

let globalBroadcast = null;
const MAX_CACHE_SIZE = 2;

export const useJoinedChannels = () => {
    const store = useStore();
    const key = (store.state.user?.id ?? '') + ':joined-channels';
    const cache = mem_store('channel-page');

    return cache.remember(key, () => {
        const channels = collectionV2({
            url: '/api/joined-channels',
            params: {
                per_page: 100,
            },
        });

        return channels;
    });
}

export const useChannelPage = (handle) => {
    const store = useStore();
    const key = (store.state.user?.id ?? '') + ':' + handle + ':channel-page';
    const cache = mem_store('channel-page', MAX_CACHE_SIZE);

    const page = cache.remember(key, () => {
        return reactive({
            channel: useResource(`/api/channels/${handle}`),
        });
    });

    return page;
}

export const useChannelFeed = (handle) => {
    const store = useStore();
    const key = (store.state.user?.id ?? '') + ':' + handle + ':channel-feed';
    const cache = mem_store('channel-feed', MAX_CACHE_SIZE);
    const route = useRoute();
    const router = useRouter();

    const feed = cache.remember(key, ({ onEvicted }) => {
        const posts = collectionV2({
            url: '/api/posts',
            params: {
                filters: {
                    channel: handle,
                },
            },
        });

        const savedPosts = collectionV2({
            url: '/api/posts',
            params: {
                filters: {
                    channel: handle,
                    saved: true,
                },
            },
            resolveDataUsing: (data) => {
                return data.filter(post => post.preference?.saved);
            },
        });

        const mentionsPosts = collectionV2({
            url: '/api/posts',
            params: {
                filters: {
                    channel: handle,
                    mentions: true,
                },
            },
        });

        const state = reactive({
            posts,
            savedPosts,
            mentionsPosts,
            new_posts: false,
            goToView(view) {
                router.push({
                    replace: true,
                    query: {
                        ...route.query,
                        view,
                    },
                }).then(() => {
                    let collection = null;
                    if (view === 'saved') {
                        collection = savedPosts;
                    } else if (view === 'mentions') {
                        collection = mentionsPosts;
                    } else {
                        collection = posts;
                    }

                    if (!collection) {
                        return;
                    }

                    collection.fetchOnce();
                }).catch(() => {
                    console.error('Failed to set active view');
                });
            }
        });

        let feedBroadcast = null;

        const removePost = (postId) => {
            if (!state.posts.fetched) {
                return;
            }

            const post = state.posts.data.find(post => post.id === postId);

            if (!post) {
                return;
            }

            post.deleted = true;

            setTimeout(() => {
                state.posts.data.splice(feed.posts.data.indexOf(post), 1);
            }, 5000);
        }

        const onPostDeleted = (event) => {
            removePost(event.id);
        }

        const onPostHidden = (event) => {
            removePost(event.id);
        }

        const onPostPublished = (event) => {
            axios.get(`/api/posts/${event.id}`).then(response => {
                const post = response.data.data;
                state.posts.unshift(post);
                state.new_posts = true;
            });
        }

        const postListener = usePostListener((id) => {
            return feed.posts.data.find(post => post.id === id);
        });

        onMounted(() => {
            postListener.listen();

            if (!globalBroadcast) {
                globalBroadcast = echo.channel('global');
            }
            feedBroadcast = echo.private(`channels-by-handle.${handle}`);

            globalBroadcast.listen('.post:deleted', onPostDeleted);
            globalBroadcast.listen('.post:hidden', onPostHidden);
            feedBroadcast.listen('.post:published', onPostPublished);
        });

        onEvicted(() => {
            postListener.off();
            globalBroadcast.stopListening('.post:deleted', onPostDeleted);
            globalBroadcast.stopListening('.post:hidden', onPostHidden);
            feedBroadcast.stopListening('.post:published', onPostPublished);
        });

        return state;
    });

    return feed;
}
