<script setup>
import { inject, onBeforeMount, ref, watch } from 'vue';

import { MediaNetworkState } from '@/enums';

import MediaControls from '@/components/File/MediaControls.vue';
import MediaNetworkLoader from '@/components/File/MediaNetworkLoader.vue';
import VideoPlayer from '@/components/File/VideoPlayer.vue';

const gallery = inject('gallery');

const props = defineProps({
    video: {
        type: Object,
        required: true
    }
});

const player = ref(null);
const pausedFromSwipe = ref(true);
let autoplayDefault = gallery.activeFile === props.video.id;

watch(() => gallery.activeFile === props.video.id, (active) => {
    if (!active && !player.value?.paused) {
        player.value?.pause();
        pausedFromSwipe.value = true;
        return;
    }

    if (player.value?.paused && pausedFromSwipe.value) {
        player.value?.play();
        pausedFromSwipe.value = false;
    }
});

onBeforeMount(() => {
    autoplayDefault = gallery.activeFile === props.video.id;
});
</script>

<style scoped>
.video {
    animation: scale-in 0.3s ease-in-out;
}

.image-enter-from {
    animation: scale-in 0.3s ease-in-out;
}

.image-leave-active {
    animation: scale-out 0.3s ease-in-out;
}

@keyframes scale-in {
    from {
        transform: scale3d(0.2, 0.2, 1);
    }
    to {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes scale-out {
    from {
        transform: scale3d(1, 1, 1);
    }
    to {
        transform: scale3d(0.95, 0.95, 1);
    }
}
</style>

<template>
    <div class="h-full w-full relative">
        <Transition name="image">
            <VideoPlayer ref="player" class="video relative" :src="video.url" :muted="false" :autoplay="autoplayDefault">
                <MediaControls
                    class="absolute bottom-0 left-0 z-50 px-6 py-4
                    transition-all duration-300
                    __ignore-gallery-fullscreen
                    __ignore-swipe-gestures"
                    :class="{
                        'opacity-0 h-0 bottom-[-50px]': gallery.fullScreen
                    }"
                 />
                <MediaNetworkLoader />
            </VideoPlayer>
        </Transition>
    </div>
</template>
