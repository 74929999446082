<script setup>
import appleImg from '@images/apple.png';
import googleImg from '@images/google.png';

import { onMounted, ref } from 'vue';

import { appendQueryParams } from '@/utils';
import { useAjaxForm } from '@/utils/form';
import { useLoginHandler } from '@/composables/actions';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { useToast } from '@/plugins/toast';

import CompleteSocialLogin from '@/components/Auth/CompleteSocialLogin.vue';

const emit = defineEmits(['loading']);
const store = useStore();
const toast = useToast();
const router = useRouter();
const route = useRoute();
const loginHandler = useLoginHandler();
const response = ref(null);
const loading = ref(false);

const providers = [
    {
        key: 'google',
        icon: googleImg,
        label: 'Google',
    },
    {
        key: 'apple',
        icon: appleImg,
        label: 'Apple',
    },
];

const loginSuccess = (user) => {
    loginHandler.handle(user);
    router.push({ name: 'feed' });
}

const handleCredentialResponse = (credentialResponse) => {
    response.value = credentialResponse;

    if (!response.value.success) {
        toast.error(response.value.message);
        return;
    }

    if (response.value.status === 'login_success') {
        loginSuccess(response.value.user);
        return;
    }
}

const startForm = useAjaxForm();
startForm.retrievesCsrf();

const verificationForm = useAjaxForm();
verificationForm.retrievesCsrf();

verificationForm.onSuccess((response) => {
    handleCredentialResponse(response.data);
});

verificationForm.onFinally(() => {
    loading.value = false;
    emit('loading', false);
});

const socialLogin = (provider) => {
    let redirect = appendQueryParams(window.location.href, { _slp: provider });
    redirect += '&_slc={credentials}';

    loading.value = true;
    emit('loading', true);

    startForm.post(`/auth/social/${provider}/start`, {
        data: {
            redirect,
        },
        onFinally: () => {
            loading.value = false;
            emit('loading', false);
        },
        onSuccess: (response) => {
            window.location.href = response.data.url;
        }
    });
}

onMounted(() => {
    if (route.query._slc && route.query._slp) {
        const credentials = route.query._slc;
        const provider = route.query._slp;

        router.replace({ query: { _slc: undefined, _slp: undefined } }).then(() => {
            emit('loading', true);
            loading.value = true;
            verificationForm.post(`/auth/social/${provider}/verify`, {
                data: {
                    credentials,
                }
            });
        });
    }
})
</script>

<template>
    <div class="flex gap-2 flex-col items-center">
        <button
            :disabled="loading"
            v-for="provider in providers"
            :key="provider.key"
            class="
                block w-full bg-[#fff] text-[#000] rounded-full
                hover:bg-[#f5f5f5] active:bg-[#e5e5e5] h-[36px]
                disabled:opacity-50 disabled:cursor-not-allowed
            "
            @click="socialLogin(provider.key)"
        >
            <div class="flex gap-2 mx-auto w-1/2">
                <img :src="provider.icon" :alt="provider.label" class="size-5">
                <span class="text-sm">Continue with <span class="sentence-case">{{ provider.label }}</span></span>
            </div>
        </button>
        <CompleteSocialLogin
            :open="['requires_approval', 'requires_signup'].includes(response?.status)"
            :response="response"
            :providers="providers"
            @success="loginSuccess"
            @update:open="response = null"
        />
    </div>
</template>
