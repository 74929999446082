<script setup>
import { computed, onMounted, ref } from "vue";
import { register } from 'swiper/element/bundle';

import IconCancelBold from '@/components/Icons/CancelBold.vue';
import FileState from '@/components/Form/File/FileState.vue';
import FilePickerVideoDisplay from '@/components/Form/File/FilePickerVideoDisplay.vue';

register();

const props = defineProps({
    picker: {
        required: false,
        type: Object,
    }
});

const showImage = ref(false);

onMounted(() => {
    setTimeout(() => {
        showImage.value = true;
    }, 100);
});
</script>

<template>
    <div>
        <swiper-container
            slides-per-view="1.5"
            no-swiping-class="__ignore-swipe-gestures"
        >
            <swiper-slide
                v-for="(file, index) in picker?.getDisplayFiles ?? []"
                :key="index">
                    <div class="relative h-[306px] flex items-end">
                        <div class="relative h-[290px] w-[250px] rounded-md cursor-pointer">
                            <FileState :file="file" />
                            <template v-if="showImage">
                                <img
                                    v-if="file.type == 1"
                                    class="h-full w-full object-cover rounded-[inherit] pointer-events-none"
                                    :src="(file.thumbnails && file.thumbnails.find((t) => t.height == 900)) ? file.thumbnails.find(t=>t.height == 900).url : file.url"
                                    loading="lazy"
                                />

                                <FilePickerVideoDisplay v-else-if="file.type == 2" :video="file" :enabled="file.status == 2" />
                            </template>
                        </div>
                        <div v-if="file.status != 1 " class="absolute top-6 flex justify-between px-2 w-full">
                            <button
                                @click="picker.removeFile(file.id)"
                                aria-label="delete file"
                                type="button"
                                class="flex items-center justify-center bg-[#1f1f1f] text-[#ffffff] size-7 rounded-full"
                            >
                                <IconCancelBold class="size-5" />
                            </button>
                        </div>
                    </div>
            </swiper-slide>
        </swiper-container>
    </div>
</template>
