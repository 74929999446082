import axios from '@/axios';
import echo from '@/plugins/echo';

export const chunkUploader = ({
    file,
    data,
    onStateUpdate,
    onProgressUpdate,
    onError,
    onComplete,
}) => {
    const resource = {
        id: null,
        file: null,
        state: null,
    };

    const chunks = [];
    const chunkSize = 5 * 1024 * 1024; // 5mb
    let totalChunkedSize = 0;
    let progress = 0;
    const totalChunks = Math.ceil(file.size / chunkSize);
    let channel = null;

    const splitFileIntoChunks = () => {
        while (totalChunkedSize < file.size) {
            const end = Math.min(totalChunkedSize + chunkSize, file.size);
            const chunk = file.slice(totalChunkedSize, end);
            chunks.push({
                seq: chunks.length + 1,
                content: chunk,
                uploaded: false,
                progress: 0,
            });
            totalChunkedSize = end;
        }
    }

    const handleError = (err) => {
        updateResource({
            state: 0,
        });

        if (!onError) return;

        onError(err);
    }

    const updateResource = (data) => {
        Object.assign(resource, data);

        if (!onStateUpdate) return;

        onStateUpdate(data.state);
    }

    const updateProgress = () => {
        const totalProgress = chunks.reduce((acc, chunk) => acc + chunk.progress, 0);

        progress = Math.round(Math.min(100, totalProgress / totalChunks));

        if (!onProgressUpdate) return;

        onProgressUpdate(progress);
    }

    const uploadCompleteListener = (fileResource) => {
        if (channel) {
            channel.stopListening('.upload:complete', uploadCompleteListener);
        }

        updateResource({
            state: 4,
        });

        if (!onComplete) return;

        onComplete(fileResource);
    }

    const startUpload = async () => {
        if (resource.id) {
            return;
        }

        try {
            const response = await axios.post('/api/uploads', {
                ...data,
                total: totalChunks,
            });
            updateResource(response.data.data);

            if (!channel) {
                channel = echo.private(`uploads.${resource.id}`)
                channel.listen('.upload:complete', uploadCompleteListener)
            }
        } catch (err) {
            handleError(err);
        }
    }

    const uploadChunk = async (chunk) => {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
                chunk.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                updateProgress();
            }
        }
        try {
            const response = await axios.patch(`/api/uploads/${resource.id}`, {
                seq: chunk.seq,
                content: chunk.content,
            }, config);

            chunk.uploaded = true;
            updateResource(response.data.data);
        } catch (err) {
            handleError(err);
        }

        return chunk.uploaded;
    }

    const completeUpload = async () => {
        const pending = chunks.filter((chunk) => !chunk.uploaded);

        if (pending.length > 0) return;

        try {
            const response = await axios.post(`/api/uploads/${resource.id}/complete`);
            updateResource(response.data.data);
        } catch (err) {
            handleError(err);
        }
    }

    return {
        async upload() {
            splitFileIntoChunks();
            await startUpload();

            const pendingChunks = chunks
                .filter((chunk) => !chunk.uploaded)
                .sort((chunk) => chunk.seq);

            for (const chunk of pendingChunks) {
                const uploaded = await uploadChunk(chunk);

                if (!uploaded) break;
            }

            await completeUpload();
        },
        retry() {
            //
        }
    }
}
