<script setup>
import { DialogRoot, DialogContent, DialogPortal, DialogOverlay } from 'radix-vue';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const emit = defineEmits(['update:open']);

const props = defineProps({
    animation: {
        type: String,
        default: 'from-bottom'
    },
    noBackdropBlur: {
        type: Boolean,
        default: false
    },
    open: {
        type: Boolean,
        default: false
    },
    id: {
        type: String,
        default: null
    }
});

const router = useRouter();
const route = useRoute();

const registerRouteModal = () => {
    if (!props.id) return;

    if (route.query.modal == props.id) {
        emit('update:open', true);
    } else if (props.open == true) {
        router.push({
            path: route.path,
            params: route.params,
            query: {
                modal: props.id,
                ...route.query
            }
        })
    }

    watch(() => route.query.modal, (modal) => {
        if (modal == props.id) {
            emit('update:open', true);
        } else {
            emit('update:open', false);
        }
    });

    watch(() => props.open, (open) => {
        if (open) {
            router.push({
                path: route.path,
                params: route.params,
                query: {
                    modal: props.id,
                    ...route.query
                }
            })
        } else if (route.query.modal == props.id) {
            router.back();
        }
    });
}

onMounted(() => {
    registerRouteModal();
});
</script>

<template>
    <DialogRoot :open="open" @update:open="$emit('update:open', $event)">
        <DialogPortal>
            <DialogOverlay
                :class="{
                    'backdrop-blur-sm': !noBackdropBlur
                }"
                class="bg-[#000000]/20 data-[state=open]:animate-overlayShow data-[state=closed]:animate-overlayHide fixed inset-0 z-50" />
            <DialogContent
                @openAutoFocus="(e) => e.preventDefault()"
                class="
                    fixed focus:outline-none z-50
                    md:left-1/2 md:-translate-x-1/2
                    md:bottom-auto
                    md:top-1/2 md:-translate-y-1/2
                "
                :class="[
                    $store.state.deviceType === 'mobile' ? 'data-[state=open]:animate-in data-[state=closed]:animate-out' : '',
                    animation === 'from-bottom'
                        ? 'bottom-0 data-[state=open]:slide-in-from-bottom-[100%] data-[state=closed]:slide-out-to-bottom-[100%]'
                        : 'top-0 data-[state=open]:slide-in-from-top-[100%] data-[state=closed]:slide-out-to-top-[100%]'
                ]"
                >
                    <slot></slot>
            </DialogContent>
        </DialogPortal>
    </DialogRoot>
</template>
