<script setup>
import autosize from 'autosize';
import { nextTick, onMounted, reactive, ref, watch } from 'vue';

import { collectionV2 } from '@/composables/resource';
import { debounce } from '@/utils';

import Input from '@/components/Form/Input.vue';
import DropdownMenuRoot from '@/components/DropdownMenu/DropdownMenuRoot.vue';
import DropdownMenuPortal from '@/components/DropdownMenu/DropdownMenuPortal.vue';
import DropdownMenuContent from '@/components/DropdownMenu/DropdownMenuContent.vue';
import DropdownMenuItem from '@/components/DropdownMenu/DropdownMenuItem.vue';
import DropdownMenuSeparator from '@/components/DropdownMenu/DropdownMenuSeparator.vue';
import DropdownMenuTrigger from '@/components/DropdownMenu/DropdownMenuTrigger.vue';

const emit = defineEmits(['input']);
const model = defineModel();

const props = defineProps({
    rows: {
        default: 2,
    },
    maxRows: {
        default: 15,
    },
    placeholder: {
        type: String,
        required: false,
    },
    disableUsernameSuggestion: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        required: false,
    },
    id: {
        type: String,
        required: false,
    },
});

const textarea = ref(null);
const activeSuggestion = ref(null);
const suggestionDropdown = ref(null);
const chatbox = ref(null);

const users = collectionV2({
    url: '/api/users',
});

const suggestions = reactive({
    username: {
        placeholder: 'Search username',
        searching: false,
        debouncedSearch: null,
        data: [],
        reset() {
            users.reset();
            this.data = [];
        },
        search(value) {
            if (!value || !value.trim()) {
                this.reset();

                return;
            }

            users.setParams({
                query: value.trim(),
                per_page: 10,
            });

            users.fetch()
                .then(() => {
                    this.data = users.data.map((user) => {
                        return {
                            title: user.name,
                            subtitle: '@' + user.profile.username,
                            image: user.profile.avatar?.url,
                            value: user.profile.username,
                        };
                    });
                })
                .finally(() => {
                    this.searching = false;
                });
        },
        onOpened(defaultValue) {
            this.debouncedSearch = debounce((value) => {
                this.search(value);
            }, 600);

            if (defaultValue) {
                this.search(defaultValue);
            }
        },
        onInput(value) {
            if (value) {
                this.searching = true;
            }

            this.debouncedSearch(value);
        },
        onClosed() {
            this.reset();
            this.searchUsername = null;
        }
    },
});

const maxHeight = props.maxRows * 24;

const insert = (text) => {
    const el = textarea.value;
    const start = el.selectionStart;
    const end = el.selectionEnd;
    const value = el.value;

    model.value = value.slice(0, start) + text + value.slice(end);

    nextTick(() => {
        el.selectionStart = el.selectionEnd = start + text.length;
        el.focus();
        autosize.update(textarea.value);
    });
}

const filterSuggestion = (words) => {
    if (props.disableUsernameSuggestion) {
        return;
    }

    const match = words.match(/@$/);

    if (match) {
        return openSuggestion('username');
    }
}

const handleInput = (e) => {
    const wordsBeforeCursor = e.target.value.slice(0, e.target.selectionStart).split(' ').pop();
    filterSuggestion(wordsBeforeCursor);

    emit('input', e.target.value);
}

const handleSuggestionInput = (e) => {
    const suggestion = suggestions[activeSuggestion.value];

    if (suggestion.onInput) {
        suggestion.onInput(e.target.value);
    }
}

const handleSuggestionSelected = (e, data) => {
    e.preventDefault();
    const input = chatbox.value.querySelector('.suggestion-input');
    input.value = data.value;

    insert(input.value);

    emit('input', textarea.value.value);
    closeSuggestion();
}

const openSuggestion = (key, defaultValue) => {
    const suggestion = suggestions[key] ?? null;

    if (!suggestion) {
        throw new Error(`Suggestion key [${key}] not found`);
    }

    activeSuggestion.value = key;

    nextTick(() => {
        const input = chatbox.value.querySelector('.suggestion-input');
        if (defaultValue) {
            input.value = defaultValue;
        }
        input.focus();
    });

    if (suggestion.onOpened) {
        suggestion.onOpened(defaultValue);
    }
}

const suggestionSelected = (e, data) => {
    //
}

const closeSuggestion = () => {
    const suggestion = suggestions[activeSuggestion.value];

    activeSuggestion.value = null;

    if (suggestion && suggestion.onClosed) {
        suggestion.onClosed();
    }

    nextTick(() => {
        chatbox.value.querySelector('.textarea').focus();
    });
}

watch(model, () => {
    nextTick(() => autosize.update(textarea.value));
});

onMounted(() => {
    autosize(textarea.value);
});

defineExpose({
    focus() {
        chatbox.value.querySelector('.textarea').focus();
    },
    input(value) {
        textarea.value.value = value;
        model.value = value;
        emit('input', value);
        nextTick(() => autosize.update(textarea.value));
    },
    append(value) {
        const originalValue = textarea.value.value ?? '';
        const originalSelectionStart = textarea.value.selectionStart;
        const originalSelectionEnd = textarea.value.selectionEnd;
        this.input(originalValue + value);
        nextTick(() => {
            textarea.value.setSelectionRange(originalSelectionStart, originalSelectionEnd);
        });
    }
});
</script>

<template>
    <div ref="chatbox" class="chatbox">
        <DropdownMenuRoot ref="suggestionDropdown" :open="activeSuggestion !== null" @update:open="closeSuggestion">
            <DropdownMenuTrigger as="div">
            </DropdownMenuTrigger>
            <DropdownMenuContent :side-offset="0" side="bottom" align="start">
                <form @submit.prevent>
                    <div class="px-2">
                        <Input class="suggestion-input" @input="handleSuggestionInput" :placeholder="suggestions[activeSuggestion]?.placeholder" />
                    </div>
                    <ul class="mt-2" v-if="activeSuggestion && suggestions[activeSuggestion].data.length > 0">
                        <li v-for="(data, i) in suggestions[activeSuggestion].data" :key="i">
                            <DropdownMenuItem as="button" type="button" @select="(e) => handleSuggestionSelected(e, data)">
                                <div class="">{{ data.title }}</div>
                                <div v-if="data.subtitle" class="opacity-70">{{ data.subtitle }}</div>
                            </DropdownMenuItem>
                        </li>
                    </ul>
                </form>
            </DropdownMenuContent>
        </DropdownMenuRoot>
        <textarea
            class="textarea"
            @input="handleInput"
            ref="textarea"
            :rows="rows"
            v-model="model"
            :placeholder="placeholder"
            :style="`max-height: ${maxHeight}px;`"
            :required="required"
            :disabled="disabled"
            :name="name"
            :id="id"
        ></textarea>
    </div>
</template>

<style lang="postcss">
.chatbox {
    @apply flex items-center;
}
.chatbox .textarea {
    @apply focus:outline-none;
    background: unset;
    padding: 0;
    margin: 0;
    border: unset;
    box-shadow: unset;
    width: 100%;
    resize: none;

    &::placeholder {
        @apply opacity-50;
    }

    @focus {
        box-shadow: unset;
    }
}
</style>
