<script setup>
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useSettings } from '@/settings';
import { useStore } from '@/store';

import LoadingScreen from '@/components/LoadingScreen.vue';
import NotificationPermissionPrompt from '@/components/Permissions/NotificationPermissionPrompt.vue';

const props = defineProps({
    pageData: {
        type: Object,
        required: true,
    }
});

const routeBind = (route) => {
    return {
        ...route.params,
        pageData: props.pageData[route.path] ?? undefined,
    };
};

const settings = useSettings();
const store = useStore();
const router = useRouter();

const setDarkMode = () => document.documentElement.dataset.darkMode = settings.darkMode.toString();

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', function(event) {
        if (event.data.type !== 'navigate') {
            return;
        }

        const url = new URL(event.data.url);

        if (url.origin === window.location.origin) {
            router.push(url.pathname + url.search);
        } else {
            window.location = url.href;
        }
    });
}

onBeforeMount(() => {
    setDarkMode();
    settings.onUpdateDarkMode(() => {
        setDarkMode();
    });
});
</script>

<style lang="postcss">
body {
    @apply bg-white text-white-foreground;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
}

.fade-enter-to, .fade-leave-to {
    opacity: 1;
}
</style>
<template>
    <div>
        <router-view v-slot="{ Component, route }">
            <Component :is="Component" :route-bind="routeBind(route)" />
        </router-view>
        <NotificationPermissionPrompt />
        <LoadingScreen :open="$store.state.loading" />
    </div>
</template>
