<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import dayjs from 'dayjs';

import { useAjaxForm } from '@/utils/form';
import { abbreviateNumber, pluralize, percentage, remainingTime } from '@/utils';
import { useToast } from '@/plugins/toast';

import Button from '@/components/Button/Button.vue';
import IconCheckMark from '@/components/Icons/CheckMark.vue';
import IconDot from '@/components/Icons/Dot.vue';
import IconLoader from '@/components/Icons/Loader.vue';
import ProgressBar from '@/components/ProgressBar.vue';

const props = defineProps({
    post: {
        required: true,
        type: Object,
    },
});

const form = useAjaxForm();
const toast = useToast();

const expiration_date = ref(dayjs(props.post.poll_expiration_date));

const calculatePercentage = (votes) => {
    return Math.round(percentage(votes, props.post.votes_count));
};

const vote = (optionId) => {
    if (props.post.poll_expiration_date < new Date()) {
        props.post.poll_expired = true;
        toast.error('This poll has ended');
        return;
    }

    // first update the local data before sending the request to allow for instant feedback
    const option = props.post.poll_options.find(option => option.id == optionId);
    option.total_votes++;
    props.post.user_vote = {
        id: null,
        poll_option: {
            ...option,
        }
    }

    props.post.votes_count++;

    form.post(`/api/poll-votes`, {
        data: {
            poll_option_id: optionId,
        },
        onSuccess(response) {
            props.post.user_vote = response.data.data;
        },
        onError(error) {
            // revert the local data if the request fails
            option.total_votes--;
            props.post.user_vote = null;
            props.post.votes_count--;
        },
    });
};

const removeVote = () => {
    form.delete(`/api/poll-votes/${props.post.user_vote.id}`, {
        onSuccess: () => {
            const userVote = props.post.user_vote;
            const option = props.post.poll_options.find(option => option.id == userVote.option.id);
            option.total_votes--;
            props.post.user_vote = null;
            props.post.votes_count--;
        },
    });
};

onMounted(() => {
    const interval = setInterval(() => {
        expiration_date.value = dayjs(props.post.poll_expiration_date);
        props.post.poll_expired = expiration_date.value.isBefore(dayjs());
    }, 1000); // Update every second to make the date reactive

    onUnmounted(() => {
        clearInterval(interval);
    });
});
</script>

<template>
    <div>
        <div class="flex flex-col gap-3" v-if="post.user_vote || post.poll_expired">
            <div
                v-for="option in post.poll_options"
                :key="option.id">
                <ProgressBar
                    class="min-h-10 rounded-xl w-full h-10 relative"
                    :title="option.value"
                    :value="calculatePercentage(option.total_votes)"
                    :max="100">
                    <div class="
                        bg-white-foreground/10 rounded-[inherit] absolute top-0 left-0 w-full h-full
                        flex justify-between items-center px-4 gap-2 text-sm
                    ">
                        <div class="min-w-0 grow truncate flex gap-2 items-center">
                            <IconCheckMark v-if="post.user_vote?.option?.id == option.id" class="size-4" />
                            {{ option.value }}
                        </div>
                        <div class="shrink-0">{{ calculatePercentage(option.total_votes) }}%</div>
                    </div>
                </ProgressBar>
            </div>
        </div>

        <div v-else class="flex flex-col gap-3 w-full">
            <div v-for="option in post.poll_options" :key="option.id" class="w-full">
                <button
                    type="button"
                    v-delayed-click="() => vote(option.id)"
                    class="block min-h-10 text-center text-sm w-full border border-primary rounded-xl active:scale-[1.02] transition-all"
                    capitalize>
                    {{ option.value }}
                </button>
            </div>
        </div>

        <div class="mt-4 flex justify-between items-center">
            <div class="text-white-foreground/70 flex items-center gap-1">
                <span class="text-xs leading-none">
                    {{ abbreviateNumber(post.votes_count) }} {{ pluralize('Vote', post.votes_count) }}
                </span>
                <div>
                <IconDot class="size-1.5" />
                </div>
                <span class="text-xs leading-none">
                    <template v-if="post.poll_expired">
                        Ended
                    </template>
                    <template v-else>
                        {{ remainingTime(expiration_date) }} left
                    </template>
                </span>
            </div>

            <button v-if="!post.poll_expired && post.user_vote?.id"
                @click="removeVote"
                :disabled="form.processing"
                type="button"
                class="text-xs leading-none text-white-foreground/70 disabled:opacity-50 block">
                    <IconLoader v-if="form.processing" class="size-4" />
                    <span class="opacity-100" v-else>
                        Remove vote
                    </span>
            </button>
        </div>
    </div>
</template>
