<script setup>
import placeholder  from '@images/channel-placeholder.jpg';

import { onMounted, onUnmounted, ref, computed, nextTick, provide } from 'vue';

import { usePostListener } from '@/composables/listeners';
import { collection, collectionV2, usePostResource } from '@/composables/resource';
import { RouterLink } from 'vue-router';
import { PostType } from '@/enums';
import { scrollElementIntoViewIfNeeded } from '@/utils';
import { registerFileUploader } from '@/views/files/_helpers';
import echo from '@/plugins/echo';
import axios from '@/axios';

import AppButton from '@/components/Button/Button.vue';
import BottomBar from '@/views/posts/_helpers/BottomBar.vue';
import CommentList from '@/components/Comment/CommentList.vue';
import Content from '@/views/_partials/Content.vue';
import ThumbGallery from '@/components/File/ThumbGallery.vue';
import Header from '@/views/_partials/header/Header.vue';
import HeaderTitle from '@/views/_partials/header/HeaderTitle.vue';
import IconAutoRenew from '@/components/Icons/AutoRenew.vue';
import PostContent from '@/components/Post/PostContent.vue';
import PostFooter from '@/components/Post/PostFooter.vue';
import PostHeader from '@/components/Post/PostHeader.vue';
import PostPoll from '@/components/Post/PostPoll.vue';
import Skeleton from '@/components/Renderer/Skeleton.vue';
import NewComment from '@/components/Notifications/NewComment.vue';

registerFileUploader();

provide('onReplyButtonClicked', function (data) {
    bottomBar.value.openReplyForm(data);
});

const props = defineProps({
    pageData: {
        type: Object,
        required: false,
    },
    id: {
        required: true,
    }
});

const bottomBar = ref();
const post = usePostResource(props.id);
const sortOption = ref('2');
const commentCreatedScrollTarget = ref();

const postListener = usePostListener(() => post.data);

if (props.pageData?.data) {
    post.fill(props.pageData);
}

const comments = collectionV2({
    url: `/api/posts/${props.id}/comments`,
    params: {
        view: sortOption.value,
    }
});

const pendingComments = ref([]);

const openPendingComments = () => {
    const newComments = pendingComments.value;
    pendingComments.value = [];
    comments.unshift(...newComments);
}

const incrementCommentsCount = () => {
    post.data.comments_count++;

    if (post.data.comments_count < 1000) {
        post.data.comments_count_abbrv = post.data.comments_count;
    }
}

const commentCreated = (comment) => {
    newComment(comment);
    nextTick(() => {
        scrollElementIntoViewIfNeeded({
            el: commentCreatedScrollTarget.value,
            extra: -70,
        });
    })
}

const newComment = (comment) => {
    comments.unshift(comment);
    incrementCommentsCount();
}

const commentDeleted = (comment) => {
    comments.delete(comment.id);

    post.data.comments_count--;

    if (post.data.comments_count < 1000) {
        post.data.comments_count_abbrv = post.data.comments_count;
    }
}

const onPostFetched = () => {
    comments.fetch();

    postListener.listen();

    globalChannel = echo.channel('global');
    globalChannel.listen('.post:hidden', onPostHidden);
    globalChannel.listen('.post:deleted', onPostDeleted);

    postChannel = echo.private(`posts.${props.id}`);
    postChannel.listen('.comment:published', onCommentPublished);
}

const onPostHidden = (event) => {
    if (event.id === post.data.id) {
        post.data.status = 4;
    }
}

const onPostDeleted = (event) => {
    if (event.id === post.data.id) {
        post.data.deleted = true;
    }
}

const onCommentPublished = (event) => {
    if (comments.idExists(event.id)) {
        return;
    }

    incrementCommentsCount();

    axios.get(`/api/comments/${event.id}`).then((response) => {
        const data = response.data.data;
        data._clientMeta = {
            isNew: true,
        };
        pendingComments.value.push(data);
    });
};

let globalChannel = null;
let postChannel = null;

onMounted(() => {
    if (!post.filled()) {
        post.fetch({
            onsuccess: () =>  {
                onPostFetched();
            }
        });
    } else {
        onPostFetched();
    }
});

onUnmounted(() => {
    postListener.off();
    if (globalChannel) {
        globalChannel.stopListening('.post:hidden', onPostHidden);
        globalChannel.stopListening('.post:deleted', onPostDeleted);
    }

    if (postChannel) {
        postChannel.stopListening('.comment:published', onCommentPublished);
    }
});

const sortComment = (event) => {
    comments.setParams ({
        view: sortOption.value
    });
    comments.refresh();
};


</script>

<template>
    <Content class="bg-gray text-gray-foreground">
        <Header with-back-button>
            <HeaderTitle v-if="post.filled()">
                {{ PostType.QUESTION == post.data.type ? 'Question' : (PostType.POLL == post.data.type ? 'Poll' : 'Post') }}
            </HeaderTitle>
            <HeaderTitle v-else>Post</HeaderTitle>
        </Header>

        <div class="bg-white text-white-foreground" v-if="!post.filled() && post.fetching()">
            <Skeleton :count="1" ignore-height />
        </div>
        <observable-elements v-if="post.filled()">
            <template v-if="(post.data.status === 4 && post.data.user.id !== $store.state.user.id) || post.data.deleted">
                <div class="flex items-center justify-center h-[calc(100vh-210px)] lg:h-[400px]">
                    <p class="text-base text-center font-bold italic">This post has been deleted</p>
                </div>
            </template>
            <template v-else>
                <div v-if="post.data.status === 4" class="p-4 bg-danger">
                    This post has been deleted by channel admins.
                </div>
                <article class="bg-white text-white-foreground p-4 flex flex-col" v-track-event:view="{
                    source: 'post page',
                    item_id: post.data.id,
                    item_type: 'post',
                }">
                    <PostHeader :post="post.data" />

                    <PostContent class="mt-2" :post="post.data" />
                    <PostPoll class="mt-2" v-if="post.data.type === PostType.POLL" :post="post.data" />
                    <ThumbGallery v-if="post.data.files" class="mt-2" :files="post.data.files" :gallery-id="'post-' + post.data.id" />
                    <PostFooter class="mt-2" :post="post.data" />
                </article>

                <div v-if="!post.data.channel.user_membership || post.data.channel.user_membership.status !== 1" class="flex flex-col items-center text-center gap-4 mt-8">
                    <div>
                        You must be a member of <span class="font-semibold">{{ post.data.channel.name }}</span> before you can comment.
                    </div>
                    <AppButton color="primary-border" class="text-sm p-4" capitalize :as="RouterLink" :to="{ name: 'channels.info', params: { handle: post.data.channel.name } }">
                        Join Channel
                    </AppButton>
                </div>

                <div v-if="comments.data && comments.data.length > 0" class="flex justify-between items-center px-4 py-3 gap-2">
                    <p class="font-medium">Comments </p>
                    <select v-model="sortOption" aria-label="Sort comments" @change="sortComment" class="bg-white text-white-foreground rounded-md border-white-foreground border-0 ring-1 ring-inset ring-black/20 h-9 text-sm focus:ring-2 focus:ring-black/30">
                        <option value="1">Top</option>
                        <option value="2">Recent</option>
                        <option value="3">Oldest</option>
                    </select>
                </div>

                <div ref="commentCreatedScrollTarget">
                </div>

                <button v-if="pendingComments.length > 0" @click="openPendingComments" type="button" class="bg-white py-4 px-4 text-primary font-semibold flex w-full items-center justify-center gap-1 mb-4">
                    <IconAutoRenew class="size-6" /><span>{{ pendingComments.length }} {{ pendingComments.length > 1 ? "new comments" : "new comment" }}</span>
                </button>

                <div class="md:px-4">
                    <CommentList :collection="comments" @comment-deleted="commentDeleted" :is-member="post.data.channel.user_membership && post.data.channel.user_membership.status === 1" />
                </div>
            </template>
        </observable-elements>
        <div v-if="post.getFetchStatus() === 404" class="flex items-center justify-center h-[calc(100vh-210px)] lg:h-[400px]">
            <p class="text-base text-center font-bold italic">This post has been deleted</p>
        </div>

        <BottomBar
            ref="bottomBar"
            v-if="post.filled() && post.data.channel.user_membership && post.data.channel.user_membership.status === 1"
            @comment:created="commentCreated"
            :post="post.data" />

        <router-view />
    </Content>
</template>
