<script setup>
import { useRouter } from 'vue-router';

import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';
import { formatNumberWithCommas } from '@/utils';

import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';
import UserStar from '@/components/User/UserStar.vue';

const props = defineProps({
    resource: Object,
});

const toast = useToast();
const router = useRouter();
</script>

<template>
    <div>
        <Modal :open="resource.filled()" @update:open="router.back()">
            <ModalTitle>
                <h1 class="text-xl font-semibold">Your Reputation Score</h1>
            </ModalTitle>

            <div class="flex justify-center items-center mt-6 gap-2">
                <div class="text-4xl">
                    {{ formatNumberWithCommas(resource.data.score) }}
                </div>
                <UserStar size="9" :score="resource.data.score" />
            </div>

            <div class="py-4">
                <h2 class="font-semibold">Reputation Score System Overview</h2>
                <p class="mt-2">This feature is designed to reward active users with points based on their contributions and engagement within the community. </p>
                <p class="mt-2">As you accumulate points, you'll unlock special badges and levels, gaining recognition and access to monetary contests, and corperland's paid ambassadorship program.</p>
                <p class="mt-2">The score is calculated based on the following criteria:</p>
                <ul class="list-disc list-inside mt-2 flex flex-col gap-1">
                    <li>
                        <span class="font-semibold">Posting:</span> You score points for posting meaningful contents or asking questions in a public channel.
                    </li>
                    <li>
                        <span class="font-semibold">Engagement:</span> You score points by engaging with other users' contents by reacting to them and making meaningful comments or helping others with answers to their questions.
                    </li>
                    <li>
                        <span class="font-semibold">Content Quality:</span> You score points for the quality of your contents on the app. You can also loose points if your contents are not helpful to the community.
                    </li>
                    <li>
                        <span class="font-semibold">Profile Picture:</span> You score points for uploading a profile picture. This is only applicable for your first uploaded profile picture.
                    </li>
                </ul>

                <h2 class="font-semibold mt-4">Additional Information</h2>
                <ul class="list-disc list-inside mt-2">
                    <li>
                        The score is awarded daily at {{ new Date('2024-09-22T00:00:42.000000Z').toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }) }}.
                    </li>
                    <li>
                        There is a maximum daily score limit for each criteria to prevent abuse.
                    </li>
                    <li>
                        You get a reputation star when you reach 750 points. You star changes at 2,000 and 3,000 points.
                    </li>
                </ul>
            </div>
        </Modal>
    </div>
</template>
