import Hammer from 'hammerjs';

export const hammer = {
    mounted(el, binding) {
        const value = binding.value;
        const event = binding.arg;

        const hammer = new Hammer(el);
        hammer.get('swipe').set({ direction: Hammer.DIRECTION_ALL });
        hammer.on(event, value);
    },
};
