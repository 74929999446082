<script setup>
import { inject } from 'vue';

import IconVolumeHigh from '@/components/Icons/VolumeHigh.vue';
import IconVolumeOff from '@/components/Icons/VolumeOff.vue';

const player = inject('mediaPlayer');
</script>

<template>
    <button @click.stop.prevent="player.toggleMute()" aria-label="Mute media">
        <component :is="player.muted ? IconVolumeOff : IconVolumeHigh" class="size-6" aria-hidden="true" />
    </button>
</template>
