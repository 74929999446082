<script setup>
import { inject } from 'vue';

const gallery = inject('gallery');

defineProps({
    image: {
        type: Object,
        required: true
    }
});
</script>

<style scoped>
.image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    animation: scale-in 0.3s ease-in-out;
}

.image-enter-from {
    animation: scale-in 0.3s ease-in-out;
}

.image-leave-active {
    animation: scale-out 0.3s ease-in-out;
}

@keyframes scale-in {
    from {
        transform: scale3d(0.2, 0.2, 1);
    }
    to {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes scale-out {
    from {
        transform: scale3d(1, 1, 1);
    }
    to {
        transform: scale3d(0.95, 0.95, 1);
    }
}
</style>

<template>
    <div class="h-full w-full">
        <Transition name="image">
            <img class="image select-none pointer-events-none" v-if="gallery.opened" :src="image.url" />
        </Transition>
    </div>
</template>
