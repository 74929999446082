<script setup>
import { computed, ref } from 'vue';

import IconCameraAdd from '@/components/Icons/CameraAdd.vue';
import IconCancel from '@/components/Icons/Cancel.vue';
import FilePicker from '@/components/Form/FilePicker.vue';
import FileState from '@/components/Form/File/FileState.vue';

const model = defineModel();
const picker = ref();
const props = defineProps({
    roundedFull: {
        required: false,
        default: false,
    },
    ariaLabel: {
        required: false,
        type: String
    },
    size: {
        required: false,
        type: String,
    },
    isPrivate: {
        required: false,
        default: false,
        type: Boolean,
    }
});

const file = computed(() => {
    if (!picker.value) {
        return null;
    }

    return picker.value?.getDisplayFiles?.[0];
});
</script>

<template>
    <div class="size-20 relative" :class="{
        'rounded-full': roundedFull,
        'border border-white-foreground/20': !file || file.status == 2,
    }">
        <FileState v-if="file && file.state != 4" :file="file" />
        <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
            <FilePicker
                v-show="!file?.state || file?.state == 4"
                :is-private="isPrivate"
                v-model="model"
                ref="picker"
                type="button"
                :accept-video="false"
                class="bg-[#1f1f1f] text-[#ffffff] rounded-full size-8 flex items-center justify-center"
            >
                <IconCameraAdd class="size-5" />
            </FilePicker>
        </div>
        <img v-if="file" class="object-cover h-full w-full rounded-[inherit] transition-all duration-300 pointer-events-none select-none" :src="file.url">
    </div>
</template>
