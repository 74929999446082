<script setup>
import repuationImage from '@images/reputation-score.png';

import axios from '@/axios';

import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';

import AppButton from '@/components/Button/Button.vue';
import Checkbox from '@/components/Form/Checkbox.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';

const router = useRouter();
const store = useStore();

const hidden = ref(false);

const open = ref(true);

const close = () => {
    open.value = false;
    axios.patch('/api/me/popup-campaigns', {
        hidden: hidden.value,
        campaign_name: 'reputation_score',
    }).then(response => {
        store.state.user.popup_campaigns = response.data;
        store.saveUser();
    });
}

const learnMore = () => {
    close();
    router.push({
        name: 'profiles.show.reputation',
        params: {
            username: store.state.user.profile.username,
        },
    });
}

onMounted(() => {
    if (!store.state.user.popup_campaigns.reputation_score.viewed_at) {
        axios.patch('/api/me/popup-campaigns', {
            viewed: true,
            campaign_name: 'reputation_score',
        }).then(response => {
            store.state.user.popup_campaigns = response.data;
            store.saveUser();
        });
    }
});
</script>

<template>
    <Modal :open="open" @update:open="close">
        <ModalTitle>
            New Feature - Reputation Score
        </ModalTitle>

        <div class="w-full h-[200px] mt-10">
            <img
                :src="repuationImage"
                alt="Reputation Score"
                class="w-full h-full object-contain"
            >
        </div>

        <div class="mt-5">
            <p>
                We're excited to announce the launch of a brand-new feature - the <span class="font-semibold">Reputation Score</span>.
            </p>
            <p class="mt-2">
                Now, your activity on Corperland will earn you recognition and rewards.
                With each post, comment, and interaction, you can build your reputation, unlock badges, and attain new levels.
            </p>
        </div>

        <div class="mt-5">
            <Checkbox id="hide-again" v-model="hidden" class="flex items-center gap-2">
                <label class="text-sm" for="hide-again">Don't show this again</label>
            </Checkbox>
        </div>

        <div class="w-full mt-10">
            <AppButton @click="learnMore" color="primary" full>
                Learn More
            </AppButton>
        </div>
    </Modal>
</template>
