const delayedClickHandler = (callback, event) => {
    const target = event.target;
    target.dataset.click = true;

    setTimeout(() => {
        delete target.dataset.click;
        callback(event);
    }, 150);
};

export const delayedClick = {
    mounted(el, binding) {
        const value = binding.value;

        if (typeof value === 'function') {
            el.addEventListener('click', (e) => delayedClickHandler(value, e));
        }
    },
};
