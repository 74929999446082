import { app, auth, mustVerifyEmail, mustCreateProfile } from '@/routes/middlewares';

import About from '@/views/About.vue';
import App from '@/views/_base/App.vue';
import Auth from '@/views/_base/Auth.vue';
import ChannelsIndex from '@/views/channels/Index.vue';
import ChannelsEdit from '@/views/channels/_handle_/Edit.vue';
import ChannelsInfo from '@/views/channels/_handle_/Info.vue';
import ChannelsShowBase from '@/views/channels/_handle_/Base.vue';
import ChannelsShow from '@/views/channels/_handle_/Index.vue';
import CreateProfile from '@/views/CreateProfile.vue';
import ConnectionsIndex from '@/views/connections/Index.vue';
import Guidelines from '@/views/Guidelines.vue';
import Feed from '@/views/Feed.vue';
import FileUploader from '@/views/files/Uploader.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import HelpDeleteAccount from '@/views/Help/DeleteAccount.vue';
import Login from '@/views/Auth/Login.vue';
import Logout from '@/views/Auth/Logout.vue';
import Marketplace from '@/views/marketplace/Index.vue';
import NotFound from '@/components/Errors/NotFound.vue';
import Notifications from '@/views/Notifications.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import ProfileUsernameIndex from '@/views/profiles/_username_/Index.vue';
import ProfileUsernameConnect from '@/views/profiles/_username_/Connect.vue';
import ProfileUsernameDisconnect from '@/views/profiles/_username_/Disconnect.vue';
import PostIndex from '@/views/posts/_id_/Index.vue';
import ProfileReputation from '@/views/profiles/_username_/Reputation.vue';
import PostCreate from '@/views/posts/create/Index.vue';
import ReferralsIndex from '@/views/referrals/Index.vue';
import Register from '@/views/Auth/Register.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
import SearchBase from '@/views/Search/Base.vue';
import SearchChannel from '@/views/Search/Channel.vue';
import SearchPost from '@/views/Search/Post.vue';
import SearchQuestion from '@/views/Search/Question.vue';
import SearchUser from '@/views/Search/User.vue';
import Settings from '@/views/Settings/Settings.vue';
import SettingsAppearance from '@/views/Settings/Appearance.vue';
import SettingsChangeEmail from '@/views/Settings/ChangeEmail.vue';
import SettingsChangePassword from '@/views/Settings/ChangePassword.vue';
import SettingsDeleteAccount from '@/views/Settings/DeleteAccount.vue';
import SettingsEditProfile from '@/views/Settings/EditProfile.vue';
import SettingsIndex from '@/views/Settings/Index.vue';
import SettingsNotifications from '@/views/Settings/Notifications.vue';
import VerifyEmail from '@/views/VerifyEmail.vue';

import {
    ShowConversation,
    ListConversations,
    BaseComponent,
    ListConversationRequests,
    StartConversation,
} from '@/views/conversations';
import { useStore } from '@/store';

function keepDefaultView(to, from) {
    const store = useStore();
    const toMatched = to.matched[to.matched.length - 1];
    const fromMatched = from.matched[from.matched.length - 1];

    if (fromMatched) {
        toMatched.components.default = fromMatched.components.default;
    } else if (store.state.user) {
        toMatched.components.default = Feed;
    }
}

const applyToRoutes = ({ middlewares }, routes) => {
    return routes.map(route => {
        route.meta = route.meta ?? {};
        route.meta.middlewares = route.meta.middlewares ?? [];

        if (!Array.isArray(route.meta.middlewares)) {
            route.meta.middlewares = [route.meta.middlewares];
        }

        route.meta.middlewares = [...middlewares, ...route.meta.middlewares];

        if (route.children && Array.isArray(route.children)) {
            route.children = applyToRoutes({ middlewares: route.meta.middlewares }, route.children);
        }

        return route;
    });
}

export default applyToRoutes({
    middlewares: [ app ],
}, [
    {
        path: '',
        component: App,
        children: [
            ...applyToRoutes({
                middlewares: [mustVerifyEmail, mustCreateProfile],
            }, [
                {
                    path: 'help/delete-account',
                    component: HelpDeleteAccount,
                    name: 'help.delete-account',
                },
                {
                    path: 'about',
                    component: About,
                    name: 'about',
                },
                {
                    path: 'guidelines',
                    component: Guidelines,
                    name: 'guidelines',
                },
                {
                    path: 'privacy-policy',
                    component: PrivacyPolicy,
                    name: 'privacy-policy',
                },
                {
                    path: 'posts/:id',
                    component: PostIndex,
                    name: 'posts.show',
                    children: [
                        {
                            path: 'file-uploader',
                            component: FileUploader,
                            meta: {
                                middlewares: [ auth ],
                            },
                        },
                    ],
                },
                {
                    path: 'marketplace',
                    component: Marketplace,
                    name: 'marketplace',
                    meta: {
                        showNav: true,
                    },
                },
                {
                    path: 'platoon:platoon',
                    redirect:  to => {
                        localStorage.setItem('platoon', to.params.platoon);

                        return { path: '/auth/register' };
                    }
                },
                {
                    path: 'amb/:code',
                    redirect:  to => {
                        localStorage.setItem('referral_code', to.params.code);

                        return { path: '/auth/register' };
                    }
                },
            ]),

            // requires login
            ...applyToRoutes({
                middlewares: [ auth, mustVerifyEmail, mustCreateProfile ],
            }, [
                {
                    path: '',
                    name: 'feed',
                    component: Feed,
                    meta: {
                        showNav: true,
                    },
                    children: [
                        {
                            path: 'file-uploader',
                            component: FileUploader,
                        },
                    ],
                },
                {
                    path: 'posts/create',
                    component: PostCreate,
                    name: 'posts.create',
                    children: [
                        {
                            path: 'file-uploader',
                            component: FileUploader,
                        },
                    ],
                },
                {
                    path: 'channels',
                    component: ChannelsIndex,
                    name: 'channels.index',
                    meta: {
                        showNav: true,
                    },
                },
                {
                    path: 'notifications',
                    component: Notifications,
                    meta: {
                        showNav: true,
                    },
                    name: 'notifications',
                },
                {
                    path: 'channels/:handle',
                    component: ChannelsShowBase,
                    children: [
                        {
                            path: '',
                            component: ChannelsShow,
                            name: 'channels.show',
                        },
                        {
                            path: 'info',
                            component: ChannelsInfo,
                            name: 'channels.info',
                        },
                        {
                            path: 'edit',
                            component: ChannelsEdit,
                            name: 'channels.edit',
                        },
                    ],
                },
                {
                    path: 'profiles/:username',
                    component: ProfileUsernameIndex,
                    name: 'profiles.show',
                    children: [
                        {
                            path: 'connect',
                            component: ProfileUsernameConnect,
                            name: 'profiles.show.connect',
                        },
                        {
                            path: 'disconnect',
                            component: ProfileUsernameDisconnect,
                            name: 'profiles.show.disconnect',
                        },
                        {
                            path: 'reputation',
                            component: ProfileReputation,
                            name: 'profiles.show.reputation',
                        }
                    ],
                },
                {
                    path: 'connections',
                    component: ConnectionsIndex,
                    name: 'connections.index',
                },
                {
                    path: 'referrals',
                    component: ReferralsIndex,
                    name: 'referrals.index',
                },
                {
                    path: '/conversations',
                    component: BaseComponent,
                    meta: {
                        hideSidebar: true,
                    },
                    children: [
                        {
                            path: '',
                            component: ListConversations,
                            name: 'conversations.index',
                            meta: {
                                showNav: true,
                            },
                        },
                        {
                            path: 'requests',
                            component: ListConversationRequests,
                            name: 'conversations.requests',
                        },
                        {
                            path: 'start',
                            component: StartConversation,
                            name: 'conversations.start',
                        },
                        {
                            path: ':id',
                            component: ShowConversation,
                            name: 'conversations.show',
                        },
                    ],
                },
                {
                    path: 'search',
                    component: SearchBase,
                    children: [
                        {
                            path: 'post',
                            component: SearchPost,
                            name: 'search.post',
                        },
                        {
                            path: 'channel',
                            component: SearchChannel,
                            name: 'search.channel',
                        },
                        {
                            path: 'question',
                            component: SearchQuestion,
                            name: 'search.question',
                        },
                        {
                            path: 'user',
                            component: SearchUser,
                            name: 'search.user',
                        },
                    ]
                },
                {
                    path: 'settings',
                    component: Settings,
                    children: [
                        {
                            path: '',
                            component: SettingsIndex,
                            name: 'settings.index',
                            children: [
                                {
                                    path: 'delete-account',
                                    component: SettingsDeleteAccount,
                                    name: 'settings.delete-account',
                                },
                            ],
                        },
                        {
                            path: 'appearance',
                            component: SettingsAppearance,
                            name: 'settings.appearance',
                        },
                        {
                            path: 'change-email',
                            component: SettingsChangeEmail,
                            name: 'settings.change-email',
                        },
                        {
                            path: 'change-password',
                            component: SettingsChangePassword,
                            name: 'settings.change-password',
                        },
                        {
                            path: 'edit-profile',
                            component: SettingsEditProfile,
                            name: 'settings.edit-profile',
                        },
                        {
                            path: 'notifications',
                            component: SettingsNotifications,
                            name: 'settings.notifications',
                        },
                    ],
                },
            ])
        ],
    },
    {
        path: '/auth',
        component: Auth,
        children: [
            {
                path: 'login',
                component: Login,
                name: 'login',
            },
            {
                path: 'register',
                component: Register,
                name: 'register',
            },
            {
                path: 'logout',
                name: 'logout',
                component: Logout,
            },
            {
                path: 'forgot-password',
                component: ForgotPassword,
                name: 'forgot-password',
            },
            {
                path: 'reset-password/:token',
                component: ResetPassword,
                name: 'reset-password',
            }
        ],
    },
    {
        path: '/verify-email',
        component: VerifyEmail,
        name: 'verify-email',
        meta: {
            middlewares: [ auth ],
        },
    },
    {
        path: '/onboarding/create-profile',
        name: 'create-profile',
        component: CreateProfile,
        meta: {
            middlewares: [ auth, mustVerifyEmail, function ({ store }) {
                if (store.state.user && store.state.user.profile) {
                    return {
                        name: 'feed',
                    }
                }
            }],
        },
    },
    { path: '/:pathMatch(.*)*', name: 'errors.404', component: NotFound },
]);
