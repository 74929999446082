class ObservableElement extends HTMLElement {
    static observedAttributes = ['observed', 'intersected'];


    constructor() {
        super();
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
            const event = new CustomEvent(`${name}-updated`, {
                detail: {
                    value: newValue,
                    oldValue: oldValue,
                }
            });
            this.dispatchEvent(event);
        }
    }
}

export default () => {
    if (!customElements.get('observable-element')) {
        customElements.define('observable-element', ObservableElement);
    }
}
