<script setup>
import { inject, onMounted, onUnmounted, reactive, ref, provide, computed, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { register } from 'swiper/element/bundle';

import axios from '@/axios';
import { chunkUploader } from '@/composables/files';
import { getFriendlyFileSize } from '@/utils';

import EditorHeader from '@/views/files/_helpers/EditorHeader.vue';
import IconError from '@/components/Icons/Error.vue';
import IconPlay from '@/components/Icons/Play.vue';
import ImageEditor from '@/views/files/_helpers/ImageEditor.vue';
import VideoEditor from '@/views/files/_helpers/VideoEditor.vue';
import ModalPage from '@/views/files/_helpers/ModalPage.vue';

register();

defineOptions({
    name: 'files_uploader',
});

const getData = inject('fileUploaderGetData');
const destroy = inject('fileUploaderDestroy');
const imageTypes = 'image/jpeg,image/png,image/webp,image/heic';
const videoTypes = 'video/mp4,video/avi,video/mpeg,video/quicktime,video/webm';

const data = getData() ?? {};

const fileList = data.files ?? [];
const inputFiles = [];
const MAX_IMAGE_SIZE = 1024 * 1024 * 40;
const MAX_VIDEO_SIZE = 1024 * 1024 * 1024;

const createFileObject = (file) => {
    const fileType = file.type.split('/')[0];
    const fileSize = file.size;
    let isValid = true;
    let validationError = null;
    const sizeMessage = `This file(${getFriendlyFileSize(fileSize)}) will not be uploaded because it is too large.`;

    if (fileType === 'image') {
        isValid = fileSize > 0 && fileSize <= MAX_IMAGE_SIZE;
        validationError = isValid ? null : sizeMessage + ` Maximum image size is ${getFriendlyFileSize(MAX_IMAGE_SIZE)}.`;
    } else if (fileType === 'video') {
        isValid = fileSize > 0 && fileSize <= MAX_VIDEO_SIZE;
        validationError = isValid ? null : sizeMessage + ` Maximum video size is ${getFriendlyFileSize(MAX_VIDEO_SIZE)}.`;
    } else {
        isValid = false;
        validationError = 'This file type is not supported';
    }

    const obj =  {
        isValid,
        validationError,
        value: file,
        url: URL.createObjectURL(file),
        type: fileType,
        size: fileSize,
        crop: {
            data: {},
            url: null,
        },
        clip: {
            data: {},
        }
    }

    return obj;
}

for (const file of fileList) {
    inputFiles.push(createFileObject(file));
}

const router = useRouter();
const route = useRoute();
const files = ref([...inputFiles]);
const open = ref(true);
const swiper = ref(null);
const activeIndex = ref(0);
const slideReady = ref(false);

const onSlideChange = (event) => {
    if (!event.target.swiper) {
        return;
    }

    activeIndex.value = event.target.swiper.activeIndex;
}

onMounted(() => {
    setTimeout(() => {
        slideReady.value = true;
    }, 500);
});

onUnmounted(() => {
    destroy();
});

const uploader = reactive({
    activeIndex,
    activeFile: computed(() => files.value[activeIndex.value]),
    pickerData: data,
    files: computed(() => files.value),
    cropFile() {
        router.push({
            query: {
                ...route.query,
                action: 'crop'
            }
        });
    },
    editVideo() {
        router.push({
            query: {
                ...route.query,
                action: 'edit-video'
            }
        });
    },

    addFiles() {
        const picker = document.createElement('input');
        picker.type = 'file';
        picker.multiple = data.multiple;
        picker.accept = data.accept;
        picker.onchange = (e) => {
            const swipeTo = files.value.length;

            for (const file of e.target.files) {
                files.value.push(createFileObject(file));
            }

            setTimeout(() => {
                swiper.value.swiper.slideTo(swipeTo);
            }, 100);
        };
        picker.click();
    },

    deleteFile() {
        if (files.value.length === 1) {
            return this.close();
        }

        const index = swiper.value.swiper.activeIndex;
        files.value.splice(index, 1);
    },
    close() {
        open.value = false;
        setTimeout(() => {
            router.back();
        }, 300);
    },

    upload() {
        const uploads = {};

        files.value.filter(file => file.isValid).forEach((file, index) => {
            const resource = {
                id: crypto.randomUUID(),
                item_type: null,
                url: file.crop.url ?? file.url,
                width: null,
                height: null,
                type: file.type === 'image' ? 1 : 2,
                status: 1,
                order: 0,
                thumbnails: [],
                progress: 0,
            }

            const uploader = chunkUploader({
                file: file.value,
                data: {
                    is_private: data.isPrivate ? 1 : 0,
                    crop: file.crop?.data || undefined,
                    clip: file.clip?.data || undefined,
                },
                onStateUpdate(state) {
                    if (!data.onUploadStateUpdate) {
                        return;
                    }

                    const upload = uploads[index];
                    data.onUploadStateUpdate(upload.resource, state);
                },
                onProgressUpdate(progress) {
                    if (!data.onUploadProgressUpdate) {
                        return;
                    }

                    const upload = uploads[index];
                    data.onUploadProgressUpdate(upload.resource, progress);
                },
                onComplete(file) {
                    if (!data.onUploadComplete) {
                        return;
                    }

                    const upload = uploads[index];
                    data.onUploadComplete(upload.resource, file);
                },
                onError(err) {
                    if (!data.onUploadError) {
                        return;
                    }
                    const upload = uploads[index];
                    data.onUploadError(upload.resource, err);
                }
            })

            uploads[index] = {
                resource,
                uploader,
            }

            if (data.onUploadStart) {
                data.onUploadStart(resource);
            }

            uploader.upload();
        });

        this.close();
    },
});

provide('uploader', uploader);

</script>

<style>
.uploaderThumbs swiper-slide {
    opacity: 0.4;
}

.uploaderThumbs .swiper-slide-thumb-active {
    opacity: 1;
}
</style>

<template>
    <ModalPage title="File Editor" :open="open">
        <div class="h-full w-full">
            <EditorHeader class="absolute top-0 left-0 z-50 transition-all duration-300" :class="{ 'opacity-0 top-[-70px]': route.query.action }" />
            <div class="relative transition-all duration-300"
                :class="[
                    files.length <= 1 || route.query.action ?
                        'h-full' :
                        'h-[calc(100%-100px)]'
                ]">
                <swiper-container
                    v-if="slideReady"
                    class="h-full"
                    slides-per-view="1"
                    ref="swiper"
                    thumbs-swiper=".uploaderThumbs"
                    @swiperslidechange="onSlideChange"
                >
                    <swiper-slide
                        v-for="(file, index) in files"
                        :key="index">
                            <ImageEditor v-if="file.type == 'image'" :image="file" :index="index" />
                            <VideoEditor v-if="file.type == 'video'" :video="file" :index="index" />
                            <div v-if="!file.isValid" class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-full">
                                <div class="bg-[#1f1f1f] text-[#ffffff] rounded-md p-2 max-w-[250px] mx-auto">
                                    <IconError class="text-warning size-5 inline-block" />
                                    <span class="text-sm">{{ file.validationError }}</span>
                                </div>
                            </div>
                    </swiper-slide>
                </swiper-container>
                <div class="absolute bottom-4 left-0 w-full flex items-center justify-center z-50" v-if="files.length > 1 && !route.query.action">
                    <div class="bg-[#1f1f1f] text-[#ffffff] text-sm rounded-full px-2 py-1">
                        {{ activeIndex + 1 }} / {{ files.length }}
                    </div>
                </div>
            </div>

            <div class="w-full px-2 py-2 transition-all duration-300"
                :class="[
                    files.length > 1 && !route.query.action ?
                        'opacity-100 h-[100px]' :
                        'opacity-0 h-[0px] overflow-hidden'
                ]">
                <swiper-container
                    class="uploaderThumbs"
                    space-between="10"
                    slides-per-view="5"
                    free-mode="true"
                    watch-slides-progress="true"
                >
                    <swiper-slide
                        v-for="(file, index) in files"
                        :key="index">
                        <div class="size-[80px] rounded-xl relative border border-[#ffffff]/50">
                            <img v-if="file.type == 'image'" :src="file.url" class="w-full h-full object-cover rounded-[inherit] cursor-pointer pointer-events-none select-none" />
                            <video v-if="file.type == 'video'" class="w-full h-full object-cover rounded-[inherit] cursor-pointer pointer-events-none select-none">
                                <source :src="file.url">
                            </video>
                            <div class="z-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl flex items-center justify-center">
                                <IconError v-if="!file.isValid" class="text-warning size-5" />
                                <IconPlay v-else-if="file.type == 'video'" class="size-10 text-[#ffffff]" aria-hidden="true" />
                            </div>
                        </div>
                    </swiper-slide>
                </swiper-container>
            </div>
        </div>
    </ModalPage>
</template>
