<script setup>
import { inject } from 'vue';

import AppButton from '@/components/Button/Button.vue';
import IconAdd from '@/components/Icons/Add.vue';
import IconCancelX from '@/components/Icons/CancelX.vue';
import IconCrop from '@/components/Icons/Crop.vue';
import IconEdit from '@/components/Icons/Edit.vue';
import IconTrayUpload from '@/components/Icons/TrayUpload.vue';
import IconTrashCanOutline from '@/components/Icons/TrashCanOutline.vue';


const uploader = inject('uploader');
</script>

<template>
    <div class="w-full h-[60px] px-4 py-4 swiper-no-swiping flex items-center">
        <button @click="uploader.close()"
            type="button"
            aria-label="Close editor"
            class="rounded-full p-1.5 bg-[#1f1f1f] text-[#ffffff]"
        >
            <IconCancelX class="size-5" />
        </button>
        <div class="grow"></div>
        <slot />
        <div class="flex items-center gap-2 mr-3">
            <template v-if="uploader.activeFile && uploader.activeFile.isValid">
                <button v-if="uploader.activeFile?.type === 'image'" @click="uploader.cropFile()"
                    type="button"
                    aria-label="Crop file"
                    class="rounded-full p-1.5 bg-[#1f1f1f] text-[#ffffff] active:text-primary active:scale-110 transition-all duration-300"
                >
                    <IconCrop class="size-5" />
                </button>
                <button v-if="uploader.activeFile?.type === 'video'" @click="uploader.editVideo()"
                    type="button"
                    aria-label="Edit video"
                    class="rounded-full p-1.5 bg-[#1f1f1f] text-[#ffffff] active:text-primary active:scale-110 transition-all duration-300"
                >
                    <IconEdit class="size-5" />
                </button>
            </template>
            <button @click="uploader.deleteFile()"
                type="button"
                aria-label="Delete file"
                class="rounded-full p-1.5 bg-[#1f1f1f] text-[#ffffff] active:text-primary active:scale-110 transition-all duration-300"
            >
                <IconTrashCanOutline class="size-5" />
            </button>
            <button v-if="uploader.pickerData.multiple" @click="uploader.addFiles()"
                :disabled="uploader.files.length >= uploader.pickerData.max"
                type="button"
                aria-label="Add more files"
                class="rounded-full p-1.5 bg-[#1f1f1f] text-[#ffffff] disabled:opacity-50 disabled:cursor-not-allowed active:text-primary active:scale-110 transition-all duration-300"
            >
                <IconAdd class="size-5" />
            </button>
        </div>

        <AppButton
            type="button"
            @click="uploader.upload()"
            compact
            color="primary"
            aria-label="Upload files"
            roundedFull
            >
            <IconTrayUpload class="size-5" />
        </AppButton>
    </div>
</template>
