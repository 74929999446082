<script setup>
import { computed, inject, onMounted, ref } from 'vue';

import { useToast } from '@/plugins/toast';

import IconCameraAdd from '@/components/Icons/CameraAdd.vue';

const startUpload = inject('fileUploaderStartUpload');

const model = defineModel();

const imageTypes = 'image/jpeg,image/png,image/webp,image/heic';
const videoTypes = 'video/mp4,video/avi,video/mpeg,video/quicktime,video/webm';

const props = defineProps({
    acceptImage: {
        required: false,
        type: Boolean,
        default: true,
    },
    acceptVideo: {
        required: false,
        type: Boolean,
        default: true,
    },
    multiple: {
        required: false,
        type: Boolean,
        default: false,
    },
    max: {
        required: false,
        type: Number,
        default: 10,
    },
    isPrivate: {
        required: false,
        default: false,
        type: Boolean,
    },
    as: {
        required: false,
        default: 'button',
    }
});
const toast = useToast();

const getModelValue = () => {
    if (!props.multiple) {
        return [model.value];
    }

    return model.value;
}

const pickerInitialized = ref(false);
const files = ref([]);

const getAccept = computed(() => {
    if (!props.acceptImage && !props.acceptVideo) {
        return imageTypes + ',' + videoTypes;
    }

    let types = [];

    if (props.acceptImage) {
        types.push(imageTypes);
    }
    if (props.acceptVideo) {
        types.push(videoTypes);
    }

    return types.join(',');
});

const getMax = computed(() => {
    if (!props.multiple) {
        return Math.max(0, 1 - files.value.length);
    }

    return Math.max(0, props.max - files.value.length);
});

const initializePicker = () => {
    if (pickerInitialized.value) {
        return;
    }

    files.value = [...getModelValue()];

    pickerInitialized.value = true;
}

const fileChanged = (e) => {
    if (e.target.files.length > 0) {
        startUpload({
            max: getMax.value,
            multiple: props.multiple,
            accept: getAccept.value,
            isPrivate: props.isPrivate,
            files: e.target.files,
            onUploadStart: (file) => {
                if (!props.multiple) {
                    files.value = [file];
                    return;
                }

                files.value.push(file);
            },
            onUploadStateUpdate: (file, state) => {
                const index = files.value.findIndex(item => item.id === file.id);

                Object.assign(files.value[index], {
                    state: state,
                });
            },
            onUploadProgressUpdate: (file, progress) => {
                const index = files.value.findIndex(item => item.id === file.id);

                Object.assign(files.value[index], {
                    progress: progress,
                });
            },
            onUploadComplete: (file, data) => {
                const index = files.value.findIndex(item => item.id === file.id);

                Object.assign(files.value[index], data);

                const uploadedFiles = files.value.filter(file => {
                    return file.status === 2;
                });

                if (props.multiple) {
                    model.value = uploadedFiles;
                } else {
                    model.value = uploadedFiles[0];
                }
            },
            onUploadError: (file, error) => {
                const index = files.value.findIndex(item => item.id === file.id);

                Object.assign(files.value[index], {
                    status: 3,
                });

                if (!error.response) {
                    toast.error('Check your internet connection and try again.');
                    return;
                }

                if (error.response.status === 422 && error.response.data?.errors?.file) {
                    toast.error(error.response.data.errors.file[0]);
                } else if (error.response.status === 400) {
                    toast.error(error.response.data.message || 'Invalid request.');
                } else {
                    toast.error('An error occured');
                }
            },
        });
    }
}

const open = () => {
    initializePicker();
    const picker = document.createElement('input');
    picker.type = 'file';
    picker.multiple = props.multiple;
    picker.accept = getAccept.value;
    picker.onchange = fileChanged;
    picker.click();
}

const removeFile = (id) => {
    const index = files.value.findIndex(item => item.id === id);

    if (index === -1) {
        return;
    }

    const file = files.value[index];

    if (file.status !== 2) {
        files.value.splice(index, 1);
        return;
    }

    if (!props.multiple) {
        model.value = null;
        files.value = [];
        return;
    }

    files.value.splice(index, 1);

    const modelIndex = model.value.findIndex(item => item.id === file.id);

    if (modelIndex !== -1) {
        model.value.splice(modelIndex, 1);
    }
}

defineExpose({
    getDisplayFiles: computed(() => files.value.length > 0 ? files.value : getModelValue()),
    getMax,
    open,
    removeFile,
    clear: () => {
        files.value = [];
    },
});
</script>

<template>
    <component :is="as" @click="open">
        <slot></slot>
        <input :max="getMax" aria-hidden="true" v-on:change="fileChanged" hidden :multiple="multiple" ref="fileInput" type="file" :accept="getAccept">
    </component>
</template>
