class ObservableElements extends HTMLElement {
    constructor() {
        super();

        this.activeElements = [];
        this.intersectingEntries = new Map();
    }

    _observe() {
        this.querySelectorAll('observable-element').forEach(element => {
            if (!element.hasAttribute('observed')) {
                element.setAttribute('observed', '');
                this.intersectionObserver.observe(element);
            }
        });
    }

    _initObserver() {
        const observerCallback = (entries) => {
            if (this.hasAttribute('disabled')) {
                return;
            }

            const maxIntersectingEntry = () => {
                return Array.from(this.intersectingEntries.values()).sort((a, b) => {
                    if (a.ratio === b.ratio) {
                        return a.timestamp - b.timestamp;
                    }

                    return b.ratio - a.ratio;
                })[0];
            }

            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.intersectingEntries.set(entry.target, {
                        target: entry.target,
                        ratio: entry.intersectionRatio,
                        timestamp: Date.now(),
                    });
                } else {
                    entry.target.setAttribute('intersected', 'false');
                    this.intersectingEntries.delete(entry.target);
                }
            });

            const maxIntersecting = maxIntersectingEntry();

            this.intersectingEntries.forEach(entry => {
                if (entry.target !== maxIntersecting.target) {
                    entry.target.setAttribute('intersected', 'false');
                } else {
                    entry.target.setAttribute('intersected', 'true');
                }
            });
        };

        this.intersectionObserver = new IntersectionObserver(observerCallback, {
            rootMargin: this.getAttribute('root-margin') || '0px',
            threshold: [0.25, 0.5, 0.75, 1.0],
        });

        this.mutationObserver = new MutationObserver(() => {
            this._observe();
        });

        this.mutationObserver.observe(this, {
            childList: true,
            subtree: true,
        });
    }

    connectedCallback() {
        this._initObserver();
        this._observe();
    }
}

export default () => {
    if (!customElements.get('observable-elements')) {
        customElements.define('observable-elements', ObservableElements);
    }
}
