<script setup>
import { nextTick, onMounted, ref } from 'vue';

import IconCancel from '@/components/Icons/Cancel.vue';
import IconImagePlusOutline from '@/components/Icons/ImagePlusOutline.vue';
import TextBox from '@/components/TextBox.vue';

const props = defineProps({
    form: {
        required: true,
        type: Object,
    },
    draftKey: {
        required: false,
    },
    showTitleField: {
        required: true,
        type: Boolean,
    },
    titleRequired: {
        required: false,
        type: Boolean,
        default: false,
    },
    titlePlaceholder: {
        required: false,
        type: String,
    },
    maxContentLength: {
        required: false,
        type: Number,
    },
    contentRequired: {
        required: false,
        type: Boolean,
    },
    contentPlaceholder: {
        required: false,
        type: String,
    },
});

const titleField = ref();
const contentField = ref();
const questionMarkAppended = ref(props.form.title?.endsWith('?'));

props.form.onSuccess(() => {
    deleteDraft();
})

const handleTitleFocusOut = () => {
    appendQuestionMarkToTitle();
}

const appendQuestionMarkToTitle = () => {
    if (!props.form.title || props.form.title.endsWith('?')) {
        return;
    }

    titleField.value.append('?');
    questionMarkAppended.value = true;
}

const handleTitleInput = () => {
    if (!questionMarkAppended.value) {
        appendQuestionMarkToTitle();
    }

    saveDraft();
}

const handleContentInput = () => {
    saveDraft();
}

const saveDraft = () => {
    if (!props.draftKey) {
        return;
    }

    const draft = {
        title: props.form.title,
        content: props.form.content,
        files: props.form.files,
        anonymously: props.form.anonymously,
        poll_options: props.form.poll_options
    };

    localStorage.setItem(props.draftKey, JSON.stringify(draft));
};

const retrieveDraft = () => {
    if (!props.draftKey) {
        return;
    }

    const savedDraft = localStorage.getItem(props.draftKey);

    if (savedDraft) {
        const draft = JSON.parse(savedDraft);
        Object.assign(props.form, draft);
    }
}

const deleteDraft = () => {
    if (!props.draftKey) {
        return;
    }

    localStorage.removeItem(props.draftKey);
}

onMounted(() => {
    retrieveDraft();
    nextTick(() => {
        setTimeout(() => {
            if (props.showTitleField) {
                titleField.value.focus();
            } else {
                contentField.value.focus();
            }
        }, 100);

    });
});

</script>

<style lang="postcss" scoped>
.textbox-field .field {
    @apply border-b border-primary border-opacity-0 pb-1;
}

.textbox-field .counter {
    @apply opacity-0 text-right text-xs mt-1;

    [data-error=true] {
        @apply text-danger;
    }
}

.textbox-field:focus-within {
    .field {
        @apply border-opacity-100;
    }

    .counter {
        @apply opacity-100;
    }
}
</style>

<template>
    <div class="w-full flex flex-col">
        <div v-if="showTitleField" class="textbox-field">
            <div class="field" @focusout="handleTitleFocusOut">
                <TextBox
                    @input="handleTitleInput"
                    ref="titleField"
                    v-model="form.title"
                    :rows="1"
                    :max-rows="3"
                    :placeholder="titlePlaceholder"
                    :required="titleRequired"
                    disable-username-suggestion
                />
            </div>
            <div class="counter">
                <span :data-error="form.title?.length > 200 || form.title?.length < 10">
                    {{ form.title?.length ?? 0 }}
                </span>
                <span>/200</span>
            </div>
        </div>

        <div class="textbox-field">
            <div class="field">
                <TextBox
                    @input="handleContentInput"
                    ref="contentField"
                    v-model="form.content"
                    :rows="1"
                    :max-rows="15"
                    :placeholder="contentPlaceholder"
                    :required="contentRequired"
                />
            </div>
            <div class="counter">
                <span :data-error="form.content?.length > maxContentLength || (contentRequired && form.content?.length < 1)">
                    {{ form.content?.length ?? 0 }}
                </span>
                <span>/{{ maxContentLength }}</span>
            </div>
        </div>
    </div>
</template>
