<script setup>
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { getFriendlyDuration, getFriendlySeconds } from '@/utils';

import ControlPlay from '@/components/File/ControlPlay.vue';
import ControlSlider from '@/components/File/ControlSlider.vue';
import IconPlay from '@/components/Icons/Play.vue';
import IconPause from '@/components/Icons/Pause.vue';
import VideoPlayer from '@/components/File/VideoPlayer.vue';

const props = defineProps({
    video: {
        type: Object,
        required: true
    }
});
const route = useRoute();
const router = useRouter();
const activeAction = ref(null);
const editMode = computed(() => route.query.action === 'edit-video');
const player = ref(null);
const range = ref(null);

const setEditMode = () => {
    activeAction.value = 'edit-video';

    if (range.value === null) {
        range.value = [0, player.value.duration];
    }
};

const save = () => {
    if (range.value === [0, player.value.duration]) {
        props.video.clip = { data: {} };
    } else {
        props.video.clip = {
            data: {
                start: range.value[0],
                end: range.value[1],
                duration: range.value[1] - range.value[0],
            }
        }
    }

    player.value.currentTime = range.value[0];

    router.back();
}

const cancel = () => {
    const start = props.video.clip.data.start || 0;
    const end = props.video.clip.data.end || (player.value.duration - start);

    range.value = [start, end];

    player.value.currentTime = range.value[0];

    router.back();
}

const destroyEditMode = () => {
    player?.value?.pause();
    activeAction.value = null;
}

const goToEditorAndPlay = () => {
    router.push({ query: { action: 'edit-video' } }).then(() => {
        player.value.play();
    });
}

watch(() => route.query.action, (value) => {
    if (value === 'edit-video') {
        setEditMode();
    } else if (activeAction.value === 'edit-video') {
        destroyEditMode();
    }
});
</script>

<template>
    <div class="w-full h-full flex flex-col relative" :class="{
        'swiper-no-swiping': editMode
    }">
        <VideoPlayer ref="player" :src="video.url" :autoplay="false" :muted="false" :start="range ? range[0] : null" :end="range ? range[1] : null">
            <div v-if="editMode" class="absolute top-0 left-0 w-full px-6 py-4">
                <ControlSlider use-range v-model="range" :min-range-steps="10" />
                <div v-if="range" class="mt-2 flex items-center justify-center w-full">
                    <span class="text-sm bg-[#1f1f1f] text-[#ffffff] px-2 py-1 rounded-full">
                        {{ getFriendlyDuration(range[0]) }} / {{ getFriendlyDuration(range[1]) }} ({{ getFriendlySeconds(range[1] - range[0]) }})
                    </span>
                </div>
            </div>

            <div v-if="editMode" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                <ControlPlay v-slot="{ paused }" class="bg-[#1f1f1f] text-[#ffffff] rounded-full p-1">
                    <component :is="paused ? IconPlay : IconPause" class="size-16" aria-hidden="true" />
                </ControlPlay>
            </div>
        </VideoPlayer>
        <div
            class="h-[60px] w-full flex items-center px-4 transition-all duration-300"
            :class="{
                'opacity-100 h-[60px]': editMode,
                'opacity-0 h-[0px] overflow-hidden': !editMode
            }"
        >
            <button type="button" class="font-semibold uppercase text-sm active:scale-110" @click="cancel">Cancel</button>
            <div class="grow"></div>
            <button type="button" class="font-semibold uppercase text-sm disabled:opacity-70 active:scale-110" @click="save">Save</button>
        </div>

        <div v-if="!editMode && video.isValid" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
            <button class="bg-[#1f1f1f] text-[#ffffff] rounded-full p-1" type="button" @click="goToEditorAndPlay">
                <IconPlay class="size-16" />
            </button>
        </div>
    </div>
</template>
