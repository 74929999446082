<script setup>
import { inject } from 'vue';

import { MediaNetworkState } from '@/enums';

import IconLoading from '@/components/Icons/Loader.vue';

const player = inject('mediaPlayer');
</script>

<template>
    <div v-if="player?.networkState === MediaNetworkState.LOADING && player?.readyState < 4" class="rounded-full p-1 bg-[#1f1f1f] text-[#ffffff]">
        <IconLoading class="size-10" />
    </div>
</template>
