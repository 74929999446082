import { createApp, h, inject } from "vue";
import Toast from "./Toast.vue";

const toastKey = Symbol('toast');

const createToastApp = () => {
    const app = createApp({
        data() {
            return {
                toasts: [],
            }
        },
        methods: {
            publish(toast) {
                this.toasts.push(toast);
            }
        },
        render() {
            return h(Toast, {
                toasts: this.toasts,
            });
        },
    });

    let instance;

    if (typeof window !== 'undefined') {
        const mountPoint = document.createElement('div');
        mountPoint.setAttribute('id', 'toast');
        document.body.appendChild(mountPoint);
        instance = app.mount(mountPoint);
    }

    return {
        publish: instance?.publish ?? (() => {}),
        success: (message) => {
            instance?.publish({
                title: message,
                color: 'success',
            });
        },
        error: (message) => {
            instance?.publish({
                title: message,
                color: 'danger',
            });
        },
        info: (message) => {
            instance?.publish({
                title: message,
                color: 'info',
            });
        },
        debug: (message) => {
            instance?.publish({
                title: message,
                color: 'info',
            });
        },
        handleAxiosError: (error) => {
            if (error.response) {
                if (error.response.status === 422) {
                    instance?.publish({
                        title: error.response.data.message || 'Invalid request.',
                        color: 'danger',
                    });
                } else if (error.response.status === 400) {
                    const twoFaRequired = error.response.headers['x-two-fa-required'];

                    if (!twoFaRequired) {
                        instance?.publish({
                            title: error.response.data.message || 'Invalid request.',
                            color: 'danger',
                        });
                    }
                } else if (error.response.status === 401) {
                    instance?.publish({
                        title: 'Login required.',
                        color: 'danger',
                    });
                } else if (error.response.status === 403) {
                    instance?.publish({
                        title: 'Unauthorized request.',
                        color: 'danger',
                    });
                } else if (error.response.status === 500) {
                    instance?.publish({
                        title: 'An error occured. Please try again later.',
                        color: 'danger',
                    });
                } else if (error.response.status === 449) {
                    // do nothing. action required
                } else {
                    instance?.publish({
                        title: 'An error occured. Please try again later.',
                        color: 'danger',
                    });

                    throw new Error(error.response.data.message);
                }
            } else if (error.request) {
                instance?.publish({
                    title: 'Request failed. Please check your internet connection and try again.',
                    color: 'danger',
                })
            }
        }
    };
}

export function useToast() {
    const toast = inject(toastKey);

    if (!toast) {
        throw new Error('useToast() is called without provider.');
    }

    return toast;
}

export function createToast() {
    return {
        install(app) {
            const toast = createToastApp();
            app.config.globalProperties.$toast = toast;
            app.provide(toastKey, toast);
        }
    }
}
