<script setup>
import { onMounted, onUnmounted, ref, getCurrentInstance } from 'vue';

const props = defineProps({
    threshold: {
        type: Number,
        default: 1.0,
    },
});
const observerElement = ref(null);
const instance = getCurrentInstance();
const observer = typeof IntersectionObserver === 'undefined' ? null : new IntersectionObserver(
    (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
            instance.emit('intersected', observer);
        } else {
            instance.emit('unintersected', observer);
        }
    },
    {
        root: null, // observing for viewport
        rootMargin: '0px',
        threshold: props.threshold,
    }
);
onMounted(() => {
    if (observerElement.value) {
        observer?.observe(observerElement.value);
    }
});

onUnmounted(() => {
  observer?.disconnect();
});

</script>

<template>
    <div ref="observerElement"><slot></slot></div>
</template>
