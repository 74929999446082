import { useEmitter } from "@/plugins/emitter";
import echo from '@/plugins/echo';

export const usePostListener = (getPost) => {
    const emitter = useEmitter();
    let globalChannel = null;

    const onUpdated = (event) => {
        if (!event.data?.id) {
            return;
        }

        const post = getPost(event.data.id);

        if (!post) {
            return;
        }

        Object.keys(event.data).forEach((key) => {
            post[key] = event.data[key];
        });
    }

    const onPostViewed = (event) => {
        const post = getPost(event);

        if (!post) {
            return;
        }

        if (post.id !== event.data.id) {
            return;
        }

        post.total_views += 1;
    }

    const onPostPreferenceSaved = (event) => {
        const post = getPost(event.data.post_id);

        if (!post) {
            return;
        }

        post.preference = event.data;
    }

    return {
        listen() {
            this.off();

            globalChannel = echo.channel('global');
            globalChannel.listen('.post:viewed', onPostViewed);

            emitter.on('.post:updated', onUpdated);
            emitter.on('.post-preference:saved', onPostPreferenceSaved);
        },
        off() {
            emitter.off('.post:updated', onUpdated);
            emitter.off('.post-preference:saved', onPostPreferenceSaved);
            globalChannel?.stopListening('.post:viewed', onPostViewed);
        }
    }
}
