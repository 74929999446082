<script setup>
import { nextTick, onMounted, ref, onUnmounted, watch, computed } from 'vue';

import { pluralize } from '@/utils';

import AppButton from '@/components/Button/Button.vue';
import Error from '@/components/Form/Error.vue';
import IconCancelX from '@/components/Icons/CancelX.vue';
import IconChevronDown from '@/components/Icons/ChevronDown.vue';
import Label from '@/components/Form/Label.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalTitle from '@/components/Modals/ModalTitle.vue';
import Select from '@/components/Form/Select.vue';
import TextBox from '@/components/TextBox.vue';

const optionsContainer = ref(null);

const props = defineProps({
    form: {
        required: true,
        type: Object,
    },
});

const settingDuration = ref(false);

const durations = ref([
    {
        label: 'Days',
        key: 'days',
        options: Array.from({ length: 8 }, (_, i) => i),
        value: 0,
    },
    {
        label: 'Hours',
        key: 'hours',
        options: Array.from({ length: 24 }, (_, i) => i),
        value: 0,
    },
    {
        label: 'Minutes',
        key: 'minutes',
        options: {},
        value: 0,
    }
]);

for (let i = 0; i <= 60; i += 5) {
    if (i > 59) {
        durations.value[2].options[59] = 59;
        break;
    }

    durations.value[2].options[i] = i;
}

const moveToNextOption = (index) => {
    const nextOption = optionsContainer.value.querySelector(`[data-option-index="${index + 1}"]`);
    nextOption.querySelector('textarea').focus();
};

const addOption = () => {
    if (props.form.poll_options.length < 20) {
        props.form.poll_options.push('');
        nextTick(() => {
            moveToNextOption(props.form.poll_options.length - 2);
        });
    }
};

const onKeyDown = (index) => {
    const lastIndex = props.form.poll_options.length - 1;

    if (lastIndex > index) {
        moveToNextOption(index);
    } else {
        addOption();
    }
}

const removeOption = (index) => {
    const isLast = index == props.form.poll_options.length - 1;

    props.form.poll_options.splice(index, 1);
    let moveTo = isLast ? props.form.poll_options.length - 2 : index - 1;

    nextTick(() => {
        moveToNextOption(Math.max(moveTo, -1));
    });
};

// const nextOrAddOption = (index) => {
//     const lastOption = optionsContainer.value.querySelector(`[data-option-index="${props.form.poll_options.length - 1}"]`);
//     const lastOptionTextarea = lastOption.querySelector('textarea');

//     if (lastOptionTextarea.value.trim() == '') {
//         lastOptionTextarea.focus();
//     } else {
//         addOption();
//     }
// };

const openDurationSettings = () => {
    durations.value.forEach(duration => {
        duration.value = props.form[duration.key];
    });
    settingDuration.value = true;
};

const setDuration = () => {
    durations.value.forEach(duration => {
        props.form[duration.key] = duration.value;
    });
    settingDuration.value = false;
};

const maxLength = 40;
const isDuplicate = (option, index) => {
    return props.form.poll_options.filter((opt, i) => opt?.trim() === option?.trim() && i !== index && opt?.trim() != "").length > 0;
};

const lengthExceeded = (option) => {
    return option.length > maxLength;
};

const isInvalid = (option, index) => {
    return isDuplicate(option, index) || lengthExceeded(option);
};

onMounted(() => {
    props.form.poll_options.push('', '')
    props.form.days = 1;
    props.form.hours = 0;
    props.form.minutes = 0;
});

onUnmounted(() => {
    props.form.poll_options = [];
    props.form.days = null;
    props.form.hours = null;
    props.form.minutes = null;
});

watch(() => durations.value,  () => {
    if (!settingDuration.value) {
        return;
    }

    const days = durations.value[0];
    const hours = durations.value[1];
    const minutes = durations.value[2];

    if (days.value == 7) {
        hours.value = 0;
        minutes.value = 0;

        hours.disabled = true;
        minutes.disabled = true;

        return;
    }

    hours.disabled = false;
    minutes.disabled = false;

    if (days.value == 0 && hours.value == 0 && minutes.value == 0) {
        nextTick(() => {
            days.value = 1;
        })
    }
}, { deep: true });
</script>

<template>
    <div>
        <div classs="px-2 pt-3 pb-4 border border-white-foreground/20 rounded-lg">
            <Label class="sr-only">Poll Options</Label>
            <div ref="optionsContainer" class="w-full flex flex-col gap-4">
                <div class="group" v-for="(option, index) in form.poll_options" :key="index">
                    <div class="flex group w-full rounded-md p-2 pr-0"
                        :class="{
                            'border border-black/40 focus-within:border-black/60': !isInvalid(option, index),
                            'border border-danger/50': isInvalid(option, index)
                        }">
                        <div class="grow min-w-0">
                            <TextBox
                                disable-username-suggestion
                                :data-option-index="index"
                                v-model="form.poll_options[index]"
                                :rows="1" :max-rows="2"
                                @keydown.enter.prevent="onKeyDown(index)"
                                :placeholder="`Option ${index + 1}`" />
                        </div>

                        <div class="w-6 shrink-0 group-focus-within:flex hidden flex-col items-center gap-2 pr-1">
                            <div class="size-5">
                                <AppButton
                                    v-if="form.poll_options.length > 2"
                                    @click="removeOption(index)"
                                    type="button"
                                    color="danger-border"
                                    full
                                    :rounded-full="false"
                                    no-size
                                    class="h-full opacity-70 hover:opacity-100"
                                    aria-label="Remove option"
                                >
                                    <IconCancelX class="size-3" />
                                </AppButton>
                            </div>
                        </div>
                    </div>

                    <div class="group-focus-within:block hidden">
                        <div v-if="lengthExceeded(option)" class="ml-auto w-fit mt-0.5">
                            <Error class="text-xs" :value="maxLength - option.length" />
                        </div>
                    </div>

                    <div class="group-focus-within:hidden">
                        <Error v-if="isDuplicate(form.poll_options[index], index)" class="" value="Duplicate option" />
                        <Error v-else-if="lengthExceeded(option)" :value="`Max ${maxLength} characters`" />
                    </div>
                </div>

                <button
                    :disabled="form.poll_options.length >= 20"
                    v-delayed-click="addOption"
                    type="button"
                    class="
                        block w-full rounded-md border-primary/30 border py-2 px-2 text-primary/100
                        active:scale-[1.02] transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed
                    "
                >
                    Add Option
                </button>
            </div>

            <div class="mt-8">
                <button
                    v-delayed-click="openDurationSettings"
                    type="button"
                    class="
                        block text-left ml-auto rounded-md border-white-foreground/20 border py-2 px-2
                        active:scale-[1.02] transition-all duration-300
                    "
                    aria-label="change poll duration">
                    <div class="flex items-center text-white-foreground/70">
                        <span class="text-xs">Poll duration</span>
                        <IconChevronDown class="size-4 -mt-0.5" />
                    </div>
                    <div class="text-sm text-primary w-full mt-0.5">
                        {{ [
                            form.days ? `${form.days} ${pluralize('day', form.days)}` : '',
                            form.hours ? `${form.hours} ${pluralize('hr', form.hours)}` : '',
                            form.minutes ? `${form.minutes} ${pluralize('min', form.minutes)}` : '',
                        ].filter(Boolean).join(', ') }}
                    </div>
                </button>
            </div>
        </div>

        <Modal v-model:open="settingDuration" no-backdrop-blur hide-close>
            <ModalTitle show class="text-lg">Set duration</ModalTitle>
            <div class="flex gap-3 w-full justify-between mt-4">
                <div class="flex flex-col items-center grow" v-for="duration in durations" :key="duration">
                    <div class="text-sm mb-3 block">{{ duration.label }}</div>
                    <div class="w-24">
                        <Select
                            :aria-label="`Select ${duration.label} duration`"
                            :name="duration.key"
                            v-model="duration.value"
                            :options="duration.options"
                            :disabled="duration.disabled" />
                    </div>
                </div>
            </div>
            <div class="mt-8 flex justify-end gap-6">
                <AppButton v-delayed-click="() => settingDuration = false" class="text-primary" compact>Cancel</AppButton>
                <AppButton v-delayed-click="setDuration" class="text-primary" compact>Set</AppButton>
            </div>
        </Modal>
    </div>
</template>
