import { ref, reactive } from 'vue';
import { getToken } from 'firebase/messaging';

import { createPlugin } from '@/plugins/firebase';
import { useAjaxForm } from '@/utils/form';
import { useToast } from '@/plugins/toast';
import { mem_cache } from '@/utils/cache';

export const promptOpened = ref(false);

const webPush = () => {
    return {
        isSupported() {
            return typeof window !== 'undefined' && 'Notification' in window && 'serviceWorker' in navigator;
        },
        async getPermission() {
            return Notification.permission;
        },
        async getToken() {
            const firebase = createPlugin();

            const registration = await navigator.serviceWorker.getRegistration('/sw.js');

            if (!registration) {
                throw new Error('No service worker registration found');
            }

            const messaging = firebase.getMessaging();

            return await getToken(messaging, { vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY, serviceWorkerRegistration: registration });
        },
        async requestPermission() {
            return Notification.requestPermission();
        }
    };
}

const iosPush = () => {
    return {
        isSupported() {
            return typeof window !== 'undefined' &&
                window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers['push-permission-request'] &&
                window.webkit.messageHandlers['push-permission-state'];
        },
        async getPermission() {
            const get = new Promise((resolve) => {
                const cb = (event) => {
                    window.removeEventListener('push-permission-state', cb);

                    switch (event.detail) {
                        case 'notDetermined':
                            resolve('default');
                            break;
                        case 'denied':
                            resolve('denied');
                          break;
                        case 'authorized':
                        case 'ephemeral':
                        case 'provisional':
                            resolve('granted');
                            break;
                        case 'unknown':
                        default:
                            resolve('default');
                            break;
                    }
                };

                window.addEventListener('push-permission-state', cb);
            });

            window.webkit.messageHandlers['push-permission-state'].postMessage('push-permission-state');

            return await get;
        },
        async getToken() {
            const token = new Promise((resolve) => {
                const cb = (event) => {
                    window.removeEventListener('push-token', cb);

                    resolve(event?.detail);
                };

                window.addEventListener('push-token', cb);
            });

            window.webkit.messageHandlers['push-token'].postMessage('push-token');

            return await token;
        },
        async requestPermission() {
            const request = new Promise((resolve) => {
                const cb = (event) => {
                    window.removeEventListener('push-permission-request', cb);

                    if (event?.detail === 'granted') {
                        resolve('granted');
                    } else {
                        resolve('denied');
                    }
                };

                window.addEventListener('push-permission-request', cb);
            });

            window.webkit.messageHandlers['push-permission-request'].postMessage('push-permission-request');

            return await request;
        }
    };
}

const getPush = () => {
    const ios = iosPush();

    if (ios.isSupported()) {
        return ios;
    }

    const web = webPush();

    if (web.isSupported()) {
        return web;
    }

    return null;
}

export const useNotificationPermission = () => {
    const push = getPush();

    if (!push) {
        return {
            supported: false,
            permission: 'default',
            prompt() {},
            promptOnce() {},
            refreshToken() {},
            enable() {},
            disable() {},
        };
    }

    const toast = useToast();
    const tokenForm = useAjaxForm();

    const promptKey = 'notification.permission.standalone';

    const getTokenRefreshTtl = () => {
        const oneHour = 60 * 60;

        return oneHour;
    };

    const saveToken = async (alert = true) => {
        const token = await push.getToken();
        tokenForm.token = token;
        tokenForm.alert = alert;

        tokenForm.post('/api/me/fcm-tokens', {
            data: {
                token,
                alert,
            },
        });

        return token;
    }

    const n = reactive({
        supported: true,
        permission: 'default',
        prompt() {
            promptOpened.value = true;
        },
        promptOnce() {
            if (localStorage.getItem(promptKey) !== null) {
                return;
            }

            this.prompt();
        },
        async refreshToken(alert = false, force = false) {
            if (this.permission !== 'granted') {
                return false;
            }

            const cacheStore = await mem_cache('device');
            const tokenRefreshed = await cacheStore.has('token_refreshed');

            if (tokenRefreshed && !force) {
                return;
            }

            await saveToken(alert);

            cacheStore.put('token_refreshed', true, getTokenRefreshTtl());

            return true;
        },
        async enable() {
            const permission = await push.requestPermission();

            this.permission = permission;

            if (permission !== 'granted') {
                return false;
            }

            await saveToken(true);

            const cacheStore = await mem_cache('device');
            cacheStore.put('token_refreshed', true, getTokenRefreshTtl());

            return true;
        },
        promptConfirmed() {
            promptOpened.value = false;
            localStorage.setItem(promptKey, 'confirmed');

            this.enable().then((granted) => {
                if (!granted) {
                    toast.info('You can reenable notifications in the settings.');
                }
            }).catch((err) => {
                toast.error('An error occurred while enabling notifications.');

                throw err;
            });
        },
        promptCancelled() {
            promptOpened.value = false;
            localStorage.setItem(promptKey, 'prompted');

            toast.info('You can reenable notifications in the settings.');
        },
    });

    push.getPermission().then((permission) => {
        n.permission = permission;
    });

    return n;
}
